@mixin device($size) {
  @if $size == small-smartphone {
    @media only screen and (max-width: 414px) { @content; }
  } @else if $size == smartphone {
    @media only screen and (max-width: 768px) { @content; }
  } @else if $size == smartphone-landscape {
    @media only screen and (min-width: 375px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) { @content; }
  } @else if $size == big-smartphone {
    @media only screen and (min-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) { @content; }
  } @else if $size == big-smartphone-landscape {
    @media only screen and (min-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) { @content; }
  } @else if $size == notebook {
    @media (max-width: 1600px) { @content; }
  } @else if $size == big-desktop {
    @media (min-width: 1800px) { @content; }
  } @else if $size == oldDesktop {
    @media (min-width: 800px) { @content; }
  }
}
