@media print {
  @page {
    margin-left: 25mm;
    margin-right: 25mm;
  }
  .ps__rail-y {
    display: none !important;
  }
  body{
    background-color: #fff;
    width: 160mm;
  }
  .table {
    border-spacing: 0;
    border: 0px solid #000;
    font-size: 9px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    td {
      border: 0px solid #000;
      height: unset;
    }
    tr {
      border: 0px solid #000;
    }
  }
  .table tbody tr td {
    height: unset !important;
  }
}
