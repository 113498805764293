@charset "UTF-8";
.btn {
  border: none;
  /* sem borda */
  padding: 0px 24px;
  /* preenchimento interno */
  text-align: center;
  /* alinhamento do texto */
  text-decoration: none;
  /* sem sublinhado */
  font-size: 10pt !important;
  /* tamanho da fonte */
  display: inline-block;
  /* exibir como bloco */
  margin: 0px 0px;
  /* margem externa */
  cursor: pointer;
  /* cursor do mouse */
  border-radius: 8px;
  /* borda arredondada */
  opacity: 0.5;
  height: 30px; }

.btn-em-aberto {
  background-color: #ff8229 !important;
  color: white;
  /* cor do texto */ }

.btn-enviado {
  background-color: #809911 !important;
  color: white;
  /* cor do texto */ }

.btn-carregado {
  background-color: #22b14c !important;
  color: white;
  /* cor do texto */ }

.btn-entregue {
  background-color: #156f30 !important;
  color: white;
  /* cor do texto */ }

.btn-recolhido {
  background-color: #0505a2 !important;
  color: white;
  /* cor do texto */ }

.btn-recolhido-parcial {
  background-color: #644ad8 !important;
  color: white;
  /* cor do texto */ }

.btn-cancelado {
  background-color: #ed1e25 !important;
  color: white;
  /* cor do texto */ }

.btn-fechado {
  background-color: #0c0c0c !important;
  color: white;
  /* cor do texto */ }

/* cor de fundo quando o mouse passa por cima */
.btn:hover {
  opacity: 0.8; }

/* cor de fundo quando o botão é pressionado */
.btn-active {
  opacity: 1; }

.btn-fs-9 {
  font-size: 9px !important; }
