.buttonReceber{
  height: 40px;
  width: 40px;
}

.vBorder{
  border-right: 1px solid #e0e0e0;
}


.mainRow{
  margin-top: 0px !important;
  margin-bottom: 2px !important;
  padding-top: 20px;
  padding: 20px 20px;
  background-color: white;
}

.table {
  td {
    vertical-align: middle !important;
    text-align: center;
  }
  th {
    text-align: center;
  }
}

.paymentDescriptionContainer {
  position: relative;
  padding-top: 20px; // Espaço para o label flutuante
}

.paymentLabel {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 5px; // Tamanho menor para o label
  color: #6c757d; // Cor do Bootstrap para texto secundário
  background-color: #fff; // Cor de fundo (ajustar conforme o fundo da sua página)
  padding: 0 4px; // Pequeno padding nas laterais do texto
  transform: translateY(-50%); // Centraliza verticalmente em relação ao topo do contêiner
}
