@import '../../../scss/mixins';

.main {
    padding-top: 60px;
    padding-left: 51px;
  //padding: 60px 0 0 51px;
  min-height: 100vh;

  @include device(smartphone) {
    padding-left: 0;
  }

  .container {
    min-height: calc(100vh - 81px);
    max-height: calc(100vh - 81px);
  }
}

.mainSeller {
  position: relative; 
  height: calc(100vh - 81px); 
  overflow-y: auto;

  .container {
    height: auto; 
    overflow: visible;
  }
}

@keyframes in {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: in 0.45s ease 0s normal forwards 1;
  -webkit-animation: in 0.45s ease 0s normal forwards 1;
}
