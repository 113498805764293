@import '../../scss/linxCustom';
@import '../../scss/mixins';

.help {
  position: absolute;
  right: 10px;
  color: red;
  font-size: 12px;
  background: #fff;
  padding: 4px 8px;
  transition: all ease 0.35s;
  font-weight: 500;
  border-radius: 3px;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  height: 1rem;
  margin-bottom: 4px;
  margin-right: 7px;

  & .path {
    stroke: $white-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}