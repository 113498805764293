@page {
  margin: 0 !important;
}

// .ui.container {
//   background-color: #fff !important;
//   width: 210mm;
//   height: 297mm;
// }

// .body.A4 {
//   overflow: unset !important;
//   margin: 0 !important;
//   height: unset !important;
// }
// .html {
//   overflow: unset !important;
// }

.noScroll {
  max-height: unset !important;
}

.overflowVisible {
  overflow: auto;
  overflow-anchor: auto;
}

.sheet {
  margin: 0;
  overflow: hidden !important;
  position: relative !important;
  box-sizing: border-box !important;
  page-break-after: always !important;
  width: 260mm !important;

  padding: 5mm;
  p {
    font-size: 11px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    text-align: justify;
    text-justify: inter-word;
  }
}

.contrato-header {
  margin: 0 auto;
  background-color: #fff !important;
  width: 260mm;
  height: 62px;
  padding: 0px;
  h1 {
    float: left;
    margin-top: 18px;
    margin-left: 15px;
  }
  button {
    height: 100%;
    margin: 0px;
    padding: 0px;
    float: right;
  }
}

@media screen {
  .sheet {
    background: white !important;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3) !important;
    margin: 5mm auto;
    p{
      font-size: 12pt !important;
      margin-bottom: 2px !important;
      margin-top: 2px !important;
      text-align: justify;
      text-justify: inter-word;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0px solid #000;
  font-size: 9px !important;
  margin-bottom: 2px !important;
  margin-top: 2px !important;
  td {
    font-size: 12px !important;
    color: #000 !important;
    height: unset !important;
  }
  tfoot th{
    font-size: 12px;
    color: #000;
    padding: 5px 20px !important;
  }
}

// .contrato.table tbody tr td {
//   height: unset !important;
// }
