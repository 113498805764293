@import '../../../../../scss/linxCustom';
@import '../../../../../scss/mixins';

.baseDados {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 70%;
  max-height: 500px;
}

h1 {
  color: $primary-color;
}

.planilha {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 450px;

  span {
    color: $primary-color;
    font-weight: bold;
    font-size: 0.875rem;
  }
}
