@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.dashboardContainer {
  display: flex;
  flex-direction: row;
  height: 80vh;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .headerTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: $primary-color;
    }
    span {
      font-size: small;
      font-style: italic;
    }

    .headerSubtitle {
      a {
        margin-left: 5px;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .bodyContainerLeft {
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    border: none;
    width: 70%;

    @media (max-height: 700px) {
      height: 90vh;
    }

    @media (max-width: 1670px) {
      flex-direction: column;
      width: 60%;

      @media (max-width: 1240px) {
        width: 100%;

        @media (max-width: 800px) {
          flex-direction: column;
          width: 100%;
        }
      }
    }

    // .header {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-between;

    //   button {
    //     margin-top: 20px;
    //     height: 35px;
    //   }
    // }

    .chartContainer {
      display: flex;
      align-items: center;
      height: 100%;

      @media (max-width: 1240px) {
        width: 100%;
      }
      .chartContent {
        height: 95%;
      }
    }
  }

  .bodyContainerRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 5px 0px;
    border: none;
    width: 30%;

    @media (max-height: 700px) {
      height: 90vh;
    }

    @media (max-width: 1870px) {
      flex-direction: column;
      align-items: flex-end;
      width: 40%;

      @media (max-width: 1745px) {
        flex-direction: column;
        align-items: flex-end;
        width: 30%;

        @media (max-width: 1670px) {
          flex-direction: column;
          align-items: flex-end;
          width: 40%;

          @media (max-width: 1240px) {
            align-items: flex-end;
            width: 50%;

            @media (max-width: 800px) {
              flex-direction: column;
              align-items: center;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.chartInfoContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.chartTitle {
  font-size: 0.875rem;
  font-family: 'Dosis', sans-serif;
}

.periodoContainer {
  display: flex;
  flex-direction: row;
  width: 90%;

  @media (max-width: 1028px) {
    flex-direction: column;
  }

  @media (max-width: 750px) {
    width: 100%;
  }

  .periodoDateTimeBox {
    display: flex;
    flex-direction: column;
    margin: 0.625rem;
    min-width: 150px;

    .periodoDateTimeBoxButton {
      display: flex;
      flex-direction: row;
      width: 100%;

      @media (max-width: 1028px) {
        flex-direction: column;

        button {
          width: 97%;
          margin-top: 5px;
          margin-left: 0px;
        }
      }

      button {
        margin-left: 5px;
      }
    }
  }
}

.periodoDateToolTipButton {
  display: flex;
  justify-content: center;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
}

.cardContainer {
  width: 90%;
  padding: 0 !important;
  margin-bottom: 20px;

  @media (max-width: 750px) {
    width: 100%;
  }

  .cardContainerInfo {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.dataGridContainer {
  width: 90%;
  height: 100%;
  padding-bottom: 0.75rem;

  @media (max-width: 750px) {
    width: 100%;
  }
}

.gridColumnHeader {
  font-size: 12px !important;
}

.panelTitle {
  padding-top: 10px;
  font-size: 1rem;
  font-weight: 600px;
}

.messageContainer {
  background-color: $secondary-color;
  border-radius: 5px;
}

.headerMessageContent {
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: $white-color;

  i,
  span {
    margin-right: 1rem;
  }

  span {
    font-size: 1rem;
  }

  i {
    font-size: 1.5rem;
    // font-weight: 400;
  }

  a {
    font-size: 1rem;
    font-weight: 600;
    color: $white-color;
    &:hover {
      color: $light-color;
    }
    i {
      font-size: 1rem;
      margin-right: 0.625rem;
      margin-left: 0.625rem;
    }
  }
}

.spinner {
  animation: rotate 1s linear infinite;
  z-index: 2;
  height: 3.5rem;
  margin-bottom: 4px;
  margin-right: 7px;

  & .path {
    stroke: $primary-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.chartLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.chartLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.gridLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartTooltip {
  h4 {
    font-size: 0.875rem;
  }
  span {
    font-size: 0.75rem;
  }
}
