$themes: (
  green-theme: (
    primary-color: #07a26d,
    primary-color-hover: #038157,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  blue-theme: (
    primary-color: #41b1ef,
    primary-color-hover: #2980b9,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  yellow-theme: (
    primary-color: #ffc107,
    primary-color-hover: #ff9200,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  red-theme: (
    primary-color: #ea6153,
    primary-color-hover: #cd0a0a,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  br-theme: (
    primary-color: #397973,
    primary-color-hover: #1f5c52,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  default-theme: (
    primary-color: #5b2e90,
    primary-color-hover: #2c004b,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  )
);

@each $theme, $cor in $themes {
  $primary-color: map-get($cor, primary-color);
  $primary-color-hover: map-get($cor, primary-color-hover);
  $secondary-color: map-get($cor, secondary-color);
  $secondary-color-hover: map-get($cor, secondary-color-hover);
  $success-color: map-get($cor, success-color);
  $success-color-hover: map-get($cor, success-color-hover);
  $danger-color: map-get($cor, danger-color);
  $danger-color-hover: map-get($cor, danger-color-hover);
  $warning-color: map-get($cor, warning-color);
  $warning-color-hover: map-get($cor, warning-color-hover);
  $info-color: map-get($cor, info-color);
  $info-color-hover: map-get($cor, info-color-hover);
  $light-color: map-get($cor, light-color);
  $light-color-hover: map-get($cor, light-color-hover);
  $dark-color: map-get($cor, dark-color);
  $dark-color-hover: map-get($cor, dark-color-hover);
  $gray-color: map-get($cor, gray-color);
  $white-color: map-get($cor, white-color);
  $breadcrumb-color: map-get($cor, breadcrumb-color);
  $navbar-color: map-get($cor, navbar-color);
  $navbar-a-color: map-get($cor, navbar-a-color);
  $widget-head-color: map-get($cor, widget-head-color);
  $widget-body-color: map-get($cor, widget-body-color);

  .#{$theme} {
    .EmpresaCardSelected {
      border: solid 2px $primary-color;
    }

    .notification {
      .circle {
        background: $primary-color;
      }
    }

    .header {
      @include device(smartphone) {
        background-color: $primary-color;
        color: $white-color;
      }

      .headerWrapper {
        @include device(smartphone) {
          background-color: $primary-color;
        }

        .navItem {
          .dropdownMenu {
            .notificationFooter {
              span {
                color: $primary-color;
              }
            }
          }
        }
      }
    }

    .result {
      .appliedFilters {
        .pill {
          background-color: $primary-color;
        }
      }
    }

    .lgpdDetails {
      .searchWrapper {
        .subTitle {
          h1 {
            color: $primary-color;
          }
        }
      }
    }

    .gerenciamentoFinanceiroResult {
      .appliedFilters {
        .pill {
          background-color: $primary-color;
        }
      }

      .tipoOperacaoColumn {
        color: $primary-color;
      }

      .quadroDados:hover {
        border-color: $primary-color;
      }
    }

    .conferenciaCaixaResult {
      .conferido {
        background-color: $secondary-color;
      }
    }

    .Login {
      .text-primary {
        color: $primary-color;
      }

      .bgPrimary {
        background-color: $primary-color;
      }

      .bgPrimaryEmporio {
        background-color: $primary-color;
      }

      .textBrandLinx {
        color: $secondary-color-hover;
      }
    }

    .dadosAdicionaisCadastro {
      .searchWrapper {
        .gridCamposPredefinidos:hover {
          border-color: $primary-color;
        }

        .gridOperacoes:hover {
          border-color: $primary-color;
        }
      }
    }
    .conferenciaTotais {
      margin-top: -16px;

      .situacao {
        color: $white-color;

        .conferido {
          background-color: $secondary-color;
        }
      }
    }

    .reportButton {
      .preReportButton {
        background-color: $primary-color;

        &:hover {
          background-color: $primary-color-hover;
          transition: 0.3s ease;
        }

        i {
          color: white;
          font-size: 40px;
        }
      }

      .dropMenu {
        .dropItem {
          &:active {
            background-color: $primary-color-hover;
            color: white;
          }
        }
      }
    }

    .conferenciaCaixaResult {
      .appliedFilters {
        .pill {
          background-color: $primary-color;
        }
      }
    }

    .pill {
      background-color: $primary-color;
    }

    .perfilOptions {
      .navItem {
        .dropdownMenu {
          .notificationFooter {
            span {
              color: $primary-color;
            }
          }
        }
      }
    }

    .ConfiguracoesGerais {
      .pannelTitle {
        color: $primary-color;
      }
    }

    .conferenciaCaixaResult {
      .card__content h5 {
        color: $primary-color;
      }

      .cardItem:hover {
        background: $primary-color;

        box-shadow: 0 4px 15px $primary-color-hover;

        button {
          color: $primary-color;
        }
      }
    }

    .conferenciaTotais {
      .info {
        span {
          color: $primary-color;
        }
      }

      .formaPagamentoColumn {
        color: $primary-color;
      }

      .formaPagamentoTotal {
        color: $primary-color;
      }
    }

    .empresaDetalhe {
      .panelWrapper {
        .panelTitle {
          color: $primary-color;
        }
      }

      .showAdditionalInfo {
        .button {
          color: $primary-color;
        }
      }
    }

    .empresasHeader {
      .updateEmpresas {
        color: $primary-color;
      }
    }

    .loader {
      .spinner {
        & .path {
          stroke: $primary-color;
        }
      }
    }

    .SearchProdutos {
      .inputResults {
        .searchingWrapper {
          circle {
            stroke: $primary-color;
          }
        }
      }
      .spinner {
        & .path {
          stroke: $white-color;
        }
      }
    }

    .linxMoneyLabel {
      .colorPurple {
        color: $primary-color;
      }

      .colorRed {
        color: $danger-color;
      }

      .colorBlack {
        color: $dark-color;

        color: #ff9200;
        color: #ffc107;
        color: #ffecb3;

        color: #2980b9;
        color: #41b1ef;
        color: #b9cae0;
      }
    }

    .Spinner {
      & .path {
        stroke: $primary-color;
      }
    }

    .rt-tr .rt-td:first-child,
    .table tbody tr td:first-child {
      color: $primary-color;
    }

    .dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title {
      background-color: $primary-color;
    }

    .table-ctn .react-grid-HeaderCell input {
      color: $primary-color;
    }

    .status-button {
      border: solid 2px $primary-color;
      color: $primary-color;
    }

    .button {
      background: $primary-color;
      box-shadow: 0px 4px 15px -2px $primary-color-hover;
    }

    .button.secondary {
      background: $secondary-color;
      box-shadow: 0px 4px 15px -2px $secondary-color-hover;
    }

    .button:active {
      box-shadow: 0px 0px 5px -2px $primary-color;
    }

    .button.secondary:active {
      box-shadow: 0px 0px 5px -2px $secondary-color;
    }

    .modal .subtitle {
      color: $primary-color;
    }

    .tab-item.active {
      background: $primary-color;
      box-shadow: 0 2px 15px $primary-color-hover;
    }

    .table-ctn .input,
    .form-input .input,
    .form-input .DateInput_input {
      border: solid 1px $primary-color;
      color: $primary-color;
    }

    .form-input .checkbox-label {
      color: $primary-color;
    }

    .form-input .checkboxinput:focus ~ .checkbox {
      border-color: $primary-color;
      box-shadow: 0px 2px 5px -2px $primary-color;
    }

    .input-file-importacao {
      border: 1px solid $primary-color;
      color: $primary-color;
    }

    .form-input .input.hascheckbox .checkboxinput:checked + .checkbox {
      border-color: $primary-color;
      background: $primary-color;
      box-shadow: 0px 2px 5px -2px $primary-color;
    }

    .form-input .input.hascheckbox .checkboxinput:checked ~ .label {
      color: $primary-color;
    }

    .form-input .input-bt > .icon {
      color: $primary-color;
    }

    .form-input .input:focus ~ .help {
      background: $primary-color;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: $primary-color !important;
    }

    .dropdown-heading {
      color: $primary-color !important;
    }

    .select-panel input[type='text']:focus {
      border-color: $primary-color !important;
    }

    .section-header-content > h2 {
      color: $primary-color;
    }

    .sidebar-nav-mobile {
      background: $primary-color;
    }

    .sidebar-nav {
      border-top: solid 1px $primary-color;
    }

    .table tbody tr td:first-child {
      color: $primary-color;
    }

    .form-input .checkboxinput:focus ~ .checkbox {
      box-shadow: 0px 4px 15px -2px $secondary-color !important;
      outline: solid 2px $primary-color;
    }

    .button:focus {
      outline: solid 2px $primary-color;
    }

    .rc-tree-node-selected {
      border: 1px $primary-color solid !important;
    }

    .form-input .input:valid,
    .form-input .input:focus,
    .form-input .input:valid ~ .input-bt,
    .form-input .input:focus ~ .input-bt,
    .form-input .DateInput_input:focus {
      border-color: $primary-color;
    }

    .rc-tree-contextmenu-inner h4:hover {
      background: $primary-color;
    }

    .rc-tree-node-selected {
      border: 1px $primary-color solid !important;
    }

    .css-2o5izw {
      box-shadow: 0 0 0 1px $primary-color !important;
    }
    .css-z5z6cw {
      background: $primary-color !important;
    }

    .payhub-card.secion-body {
      background: $primary-color;
      border: solid 1px $primary-color;
    }

    .secion-body .footer > .valor {
      color: $primary-color;
    }

    .toggle {
      color: $primary-color;
    }

    .toggle .toggle-item.active {
      background: $primary-color;
    }

    .kpi-valor {
      color: $primary-color;
    }

    .lix-flext-footer .item {
      color: $primary-color;
    }

    .table-content-bd table tbody tr:hover td {
      background: $primary-color;
    }

    .horizontalMenu, .rc-menu-horizontal > .rc-menu-submenu {
      background-color: $primary-color;
      color: $white-color;
    }

    .horizontalMenu, .rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-active {
      background-color: $primary-color;
      color: $white-color;
    }
    

    .rc-menu-horizontal > .rc-menu-submenu-active {
      background-color: $primary-color-hover;
      color: $white-color;
      border-bottom: 2px solid $primary-color;
    }

    .rc-menu-item, .rc-menu-submenu {
      background-color: $white-color;
      color: $primary-color;
    }

    .rc-menu-item-active {
      background-color: $primary-color-hover;
      color: $white-color;
      cursor: pointer;
    }

    .rc-menu-submenu-active > .rc-menu-submenu-title {
      background-color: $primary-color-hover;
      color: $white-color;
    }

    .topnav {
      background-color: $primary-color;
      color: $white-color;

      .navItem {
        color: $white-color;
        background-color: $primary-color;

        &:focus,
        &:active,
        &:focus {
          background-color: $primary-color-hover;
          color: $white-color;
        }
      }

      .navSubItem {
        background-color: $white-color;
        color: $primary-color;

        &:active,
        &:focus,
        &:hover {
          color: $white-color;
          background-color: $primary-color-hover;
        }
      }
    }

    .sidenav {
      background-color: $primary-color-hover;
      color: $white-color;

      .sidenavBrand {
        background-color: $primary-color-hover;
      }

      .buttons {
        li {
          span {
            color: $widget-body-color;

            &:focus,
            &:active,
            &:focus {
              color: $widget-body-color;
            }
          }
        }

        .navItem {
          .navLink {
            color: $white-color;

            &:hover {
              background-color: $primary-color;
            }

            &:focus,
            &.focus {
              color: $white-color;
            }
          }
        }
      }
    }

    .menus {
      color: $white-color;
      background-color: $primary-color-hover;

      &.active {
        background-color: $primary-color;
      }

      .menu {
        &:hover {
          background-color: $primary-color-hover;
        }

        .menuHeader {
          &.expanded {
            background-color: $white-color;
            color: $primary-color-hover;
          }
        }

        .menuChildren {
          background-color: $white-color;
          color: $primary-color-hover;

          .children {
            &:hover {
              background-color: $light-color;
            }
          }

          .menuChildrenChildren {
            background-color: $light-color;

            .menu4nivel {
              background-color: $gray-color;
            }
          }
        }
      }
    }

    .searchPanel {
      background-color: $white-color;

      .searchPanelWrapper {
        .lastSearched,
        .favorites {
          span {
            i {
              color: $primary-color;
            }
          }
        }

        .favorites {
          span {
            i {
              color: $primary-color;
            }
          }
        }
      }
    }

    .reportButton {
      .preReportButton {
        background-color: $primary-color;

        &:hover {
          background-color: $primary-color-hover;
        }
      }
    }

    .newLancamento {
      background-color: $primary-color;
      color: $white-color;
    }

    .HelpShareButton {
      .helpButton {
        background-color: $primary-color;

        .help {
          color: $white-color;
        }
      }
    }

    .actions {
      .newLancamento {
        background-color: $primary-color;
        color: $white-color;
      }
    }

    @keyframes in {
      0% {
        opacity: 0;
      }

      66% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    //GerenciamentoFinanceiro
    .gerenciamentoFinanceiroFilter {
      .filters {
        .appliedFilters {
          .pill {
            background-color: $primary-color;
          }
        }
        .searchWrapper {
          .input {
            .inputResults {
              .searchingWrapper {
                circle {
                  stroke: $primary-color;
                }
              }
            }
          }
          .searchOptions {
            .filters {
              .nav {
                ul {
                  .tab {
                    &.active {
                      border-right: solid 1px $primary-color;
                      border-left: solid 1px $primary-color;
                      border-bottom: solid 1px $primary-color;
                      background-color: $primary-color;
                      color: $white-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    //movimentaçãoEncerrants
    .movimentacaoEncerrantes {
      .iconButton {
        color: $primary-color;
      }
    }

    //Devextreme
    .dx-datagrid
      .dx-datagrid-header-panel.dx-state-focused
      .dx-datagrid-group-panel
      .dx-group-panel-item:focus,
    .dx-datagrid
      .dx-datagrid-header-panel.dx-state-focused
      .dx-datagrid-group-panel
      .dx-header-filter:focus {
      outline: 2px solid $primary-color;
    }
    .dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td:focus,
    .dx-datagrid
      .dx-datagrid-headers.dx-state-focused
      .dx-header-row
      td
      .dx-header-filter:focus {
      outline: 2px solid $primary-color;
    }
    .dx-datagrid
      .dx-datagrid-rowsview.dx-state-focused
      .dx-data-row
      > td.dx-command-edit
      > a.dx-link:focus {
      outline: 2px solid $primary-color;
    }
    .dx-datagrid-filter-panel.dx-state-focused .dx-icon-filter:focus,
    .dx-datagrid-filter-panel.dx-state-focused
      .dx-datagrid-filter-panel-text:focus,
    .dx-datagrid-filter-panel.dx-state-focused
      .dx-datagrid-filter-panel-clear-filter:focus {
      outline: 2px solid $primary-color;
    }
    .dx-toast-info {
      background-color: $primary-color;
    }
    .dx-popup-title {
      background: $primary-color;
      color: $white-color;
    }
    .dx-progressbar-range {
      border: 1px solid $primary-color;
      background-color: $primary-color;
    }
    .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
      background: $primary-color;
    }
    .dx-radiobutton.dx-state-hover .dx-radiobutton-icon:before {
      border-color: $primary-color;
    }
    .dx-radiobutton.dx-state-focused:not(.dx-state-active)
      .dx-radiobutton-icon:before {
      border: 1px solid $primary-color;
    }
    .dx-state-focused.dx-accordion-item {
      border-color: $primary-color;
    }
    .dx-treelist-container
      .dx-treelist-headers.dx-state-focused
      .dx-header-row
      td:focus,
    .dx-treelist-container
      .dx-treelist-headers.dx-state-focused
      .dx-header-row
      td
      .dx-header-filter:focus {
      outline: 2px solid $primary-color;
    }
    .dx-pivotgridfieldchooser .dx-area-fields.dx-drag-target {
      border-color: $primary-color;
    }
    .dx-treelist-container
      .dx-treelist-rowsview.dx-state-focused
      .dx-data-row
      > td.dx-command-edit
      > a.dx-link:focus {
      outline: 2px solid $primary-color;
      outline-offset: 2px;
    }
    .dx-treelist-filter-panel.dx-state-focused .dx-icon-filter:focus,
    .dx-treelist-filter-panel.dx-state-focused
      .dx-treelist-filter-panel-text:focus,
    .dx-treelist-filter-panel.dx-state-focused
      .dx-treelist-filter-panel-clear-filter:focus {
      outline: 2px solid $primary-color;
    }
    .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper
      .dx-tooltip-appointment-item-marker-body,
    .dx-scheduler
      .dx-scheduler-overlay-panel
      .dx-tooltip-appointment-item-marker-body {
      background: $primary-color;
    }
    .dx-filterbuilder
      .dx-filterbuilder-group
      .dx-filterbuilder-text.dx-filterbuilder-item-field:focus,
    .dx-filterbuilder
      .dx-filterbuilder-group
      .dx-filterbuilder-text.dx-filterbuilder-item-field.dx-state-active {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-theme-generic-typography a {
      color: $primary-color;
    }
    .dx-theme-accent-as-text-color {
      color: $primary-color !important;
    }
    .dx-theme-accent-as-background-color {
      background-color: $primary-color !important;
      fill: $primary-color !important;
    }
    .dx-theme-accent-as-border-color {
      border-color: $primary-color !important;
    }
    .dx-badge {
      background-color: $primary-color;
    }
    .dx-button-mode-contained.dx-button-default {
      background-color: $primary-color;
    }
    .dx-button-mode-contained.dx-button-default.dx-state-hover {
      background-color: $primary-color-hover;
    }
    .dx-button-mode-contained.dx-button-default.dx-state-focused {
      background-color: $primary-color-hover;
    }
    .dx-button-mode-contained.dx-button-default.dx-state-active {
      background-color: $primary-color-hover;
    }
    .dx-checkbox.dx-state-focused .dx-checkbox-icon {
      border: 1px solid $primary-color;
    }
    .dx-checkbox-checked .dx-checkbox-icon {
      color: $primary-color;
    }
    .dx-datagrid .dx-link {
      color: $primary-color;
    }
    .dx-checkbox-indeterminate .dx-checkbox-icon {
      color: $primary-color;
    }
    .dx-switch.dx-state-active .dx-switch-container {
      border-color: $primary-color;
    }
    .dx-switch.dx-state-hover .dx-switch-handle:before {
      background-color: $primary-color;
    }
    .dx-switch.dx-state-hover .dx-switch-container {
      border-color: $primary-color;
    }
    .dx-switch.dx-state-focused .dx-switch-container {
      border-color: $primary-color;
    }
    .dx-switch.dx-state-focused .dx-switch-handle:before {
      background-color: $primary-color;
    }
    .dx-switch-on-value .dx-switch-handle:before {
      background-color: $primary-color;
    }
    .dx-tab.dx-state-focused:after {
      border-right: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
      border-top: 1px solid $primary-color;
      border-bottom: 1px solid $primary-color;
    }
    .dx-nav-item.dx-state-focused,
    .dx-rtl .dx-nav-item.dx-state-focused {
      -webkit-box-shadow: inset 0 0 0 1px $primary-color;
      box-shadow: inset 0 0 0 1px $primary-color;
    }
    .dx-texteditor.dx-state-hover {
      border-color: $primary-color;
    }
    .dx-texteditor.dx-state-focused.dx-editor-filled,
    .dx-texteditor.dx-state-active.dx-editor-filled,
    .dx-texteditor.dx-state-focused.dx-editor-underlined,
    .dx-texteditor.dx-state-active.dx-editor-underlined,
    .dx-texteditor.dx-state-focused.dx-editor-outlined,
    .dx-texteditor.dx-state-active.dx-editor-outlined {
      border-color: $primary-color;
    }
    .dx-list.dx-list-select-decorator-enabled
      .dx-list-item.dx-state-focused
      .dx-radiobutton-icon:before,
    .dx-list.dx-list-select-decorator-enabled
      .dx-list-select-all.dx-state-focused
      .dx-radiobutton-icon:before,
    .dx-list.dx-list-select-decorator-enabled
      .dx-list-item.dx-state-focused
      .dx-checkbox-icon,
    .dx-list.dx-list-select-decorator-enabled
      .dx-list-select-all.dx-state-focused
      .dx-checkbox-icon {
      border: 1px solid $primary-color;
    }
    .dx-list:not(.dx-list-select-decorator-enabled)
      .dx-list-item.dx-state-focused {
      background-color: $primary-color;
    }
    .dx-list:not(.dx-list-select-decorator-enabled)
      .dx-list-item.dx-state-active {
      background-color: $primary-color;
    }
    .dx-list:not(.dx-list-select-decorator-enabled)
      .dx-list-item.dx-state-active
      .dx-list-slide-item-content {
      background-color: $primary-color;
    }
    .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter {
      color: $white-color;
    }
    .dx-list-slide-menu-button-menu {
      background-color: $primary-color;
    }
    .dx-device-mobile
      .dx-list.dx-list-select-decorator-enabled
      .dx-list-item.dx-state-focused
      .dx-radiobutton-icon:before,
    .dx-device-mobile
      .dx-list.dx-list-select-decorator-enabled
      .dx-list-select-all.dx-state-focused
      .dx-radiobutton-icon:before,
    .dx-device-mobile
      .dx-list.dx-list-select-decorator-enabled
      .dx-list-item.dx-state-focused
      .dx-checkbox-icon,
    .dx-device-mobile
      .dx-list.dx-list-select-decorator-enabled
      .dx-list-select-all.dx-state-focused
      .dx-checkbox-icon {
      border: 1px solid $primary-color;
    }
    .dx-device-mobile
      .dx-list:not(.dx-list-select-decorator-enabled)
      .dx-list-item.dx-state-focused {
      background-color: $primary-color;
    }
    .dx-device-mobile
      .dx-list:not(.dx-list-select-decorator-enabled)
      .dx-list-item.dx-state-active {
      background-color: $primary-color;
    }
    .dx-device-mobile
      .dx-list:not(.dx-list-select-decorator-enabled)
      .dx-list-item.dx-state-active
      .dx-list-slide-item-content {
      background-color: $primary-color;
    }
    .dx-device-mobile .dx-list-slide-menu-button-menu {
      background-color: $primary-color;
    }
    .dx-tile.dx-state-focused,
    .dx-tile.dx-state-hover {
      border-color: $primary-color;
    }
    .dx-progressbar-animating-container {
      background-image: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(5%, transparent),
        color-stop($primary-color),
        color-stop(95%, transparent)
      );
      background-image: linear-gradient(
        -90deg,
        transparent 5%,
        $primary-color,
        transparent 95%
      );
    }
    .dx-rtl .dx-progressbar .dx-progressbar-animating-container,
    .dx-rtl.dx-progressbar .dx-progressbar-animating-container {
      background-image: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(5%, transparent),
        color-stop($primary-color),
        color-stop(95%, transparent)
      );
      background-image: linear-gradient(
        -90deg,
        transparent 5%,
        $primary-color,
        transparent 95%
      );
    }
    .dx-slider-range.dx-slider-range-visible {
      border: 1px solid $primary-color;
      background: $primary-color;
    }
    .dx-slider-handle {
      background-color: $primary-color;
    }
    .dx-gallery-indicator-item {
      border: 1px solid $primary-color;
    }
    .dx-gallery-indicator-item-active,
    .dx-gallery-indicator-item-selected {
      background: $primary-color;
    }
    .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
      background: $primary-color;
    }
    .dx-datagrid-drop-highlight > td {
      background-color: $primary-color;
    }
    .dx-datagrid-focus-overlay {
      border: 2px solid $primary-color;
    }
    .dx-datagrid-pager.dx-state-focused .dx-page:focus,
    .dx-datagrid-pager.dx-state-focused .dx-page-size:focus,
    .dx-datagrid-pager.dx-state-focused .dx-navigate-button:focus {
      outline: 2px solid $primary-color;
    }
    .dx-calendar-cell.dx-calendar-selected-date,
    .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
      -webkit-box-shadow: inset 0 0 0 1000px $primary-color;
      box-shadow: inset 0 0 0 1000px $primary-color;
    }
    .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
    .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
      -webkit-box-shadow: inset 0 0 0 1px #bebebe,
        inset 0 0 0 1000px $primary-color;
      box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px $primary-color;
    }
    .dx-tabpanel.dx-state-focused .dx-multiview-wrapper {
      border: 1px solid $primary-color;
    }
    .dx-tabpanel.dx-state-focused .dx-tab-selected:after {
      border-top: 1px solid $primary-color;
      border-right: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
    }
    .dx-tabpanel.dx-state-focused
      .dx-tabs-scrollable
      .dx-tab:not(.dx-tab-selected):before {
      border-bottom: 1.5px solid $primary-color;
    }
    .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
      color: $primary-color;
    }
    .dx-scheduler-work-space-month.dx-scheduler-work-space-count
      .dx-scheduler-date-table-first-of-month {
      color: $primary-color;
    }
    .dx-scheduler-time-panel-cell.dx-scheduler-time-panel-current-time-cell {
      color: $primary-color;
    }
    .dx-scheduler-all-day-table-cell.dx-scheduler-focused-cell,
    .dx-scheduler-date-table-cell.dx-scheduler-focused-cell {
      -webkit-box-shadow: inset 0 0 0 1px $primary-color;
      box-shadow: inset 0 0 0 1px $primary-color;
    }
    .dx-scheduler-appointment {
      background-color: $primary-color;
    }
    .dx-scheduler-appointment-collector {
      background-color: $primary-color;
    }
    .dx-scheduler-appointment-collector.dx-button,
    .dx-scheduler-appointment-collector.dx-button.dx-state-hover,
    .dx-scheduler-appointment-collector.dx-button.dx-state-active,
    .dx-scheduler-appointment-collector.dx-button.dx-state-focused {
      background-color: $primary-color;
    }
    .dx-scheduler-view-switcher.dx-tabs .dx-tab.dx-state-focused:after {
      border-top: 1px solid $primary-color;
    }
    .dx-resize-frame {
      border-color: $primary-color;
    }
    .dx-resize-frame .dx-resizable-handle-corner-top-right,
    .dx-resize-frame .dx-resizable-handle-corner-top-left,
    .dx-resize-frame .dx-resizable-handle-corner-bottom-right,
    .dx-resize-frame .dx-resizable-handle-corner-bottom-left {
      background-color: $primary-color;
    }
    .dx-resize-frame .dx-resizable-handle-top::after,
    .dx-resize-frame .dx-resizable-handle-left::after,
    .dx-resize-frame .dx-resizable-handle-right::after,
    .dx-resize-frame .dx-resizable-handle-bottom::after {
      background-color: $primary-color;
    }
    .dx-fa-button.dx-fa-button-main .dx-overlay-content {
      background-color: $primary-color;
    }
    .dx-filemanager
      .dx-filemanager-thumbnails
      .dx-filemanager-thumbnails-item.dx-filemanager-item-selected {
      background: $primary-color;
    }
    .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-container {
      background-color: $white-color;
    }
    .dx-datagrid-headers {
      color: $white-color;
      background-color: $primary-color !important;
    }
    .dx-datagrid-filter-row .dx-menu-item.dx-state-focused:after {
      border: 2px solid $primary-color;
    }
    .dx-datagrid-filter-range-overlay
      .dx-overlay-content
      .dx-texteditor.dx-state-focused:after {
      border: 2px solid $primary-color;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
    .dx-datagrid-rowsview
      .dx-row-focused.dx-data-row
      > tr
      > td:not(.dx-focused),
    .dx-datagrid-rowsview
      .dx-row-focused.dx-data-row
      .dx-command-edit:not(.dx-focused)
      .dx-link {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
      border-bottom: 1px solid $primary-color;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
    .dx-datagrid-rowsview
      .dx-row-focused.dx-data-row:not(.dx-row-lines)
      > tr:first-child
      > td {
      border-top: 1px solid $primary-color;
      border-bottom: 1px solid $primary-color;
    }
    .dx-datagrid-rowsview .dx-row-focused.dx-group-row {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-datagrid-search-text {
      color: $white-color;
      background-color: $primary-color;
    }
    .dx-datagrid-pager.dx-state-focused .dx-page:focus,
    .dx-datagrid-pager.dx-state-focused .dx-page-size:focus,
    .dx-datagrid-pager.dx-state-focused .dx-navigate-button:focus {
      outline: 2px solid $primary-color;
    }
    .dx-rtl .dx-datagrid-table-fixed .dx-row.dx-group-row td {
      background-color: $primary-color;
    }
    .dx-datagrid-filter-panel .dx-icon-filter,
    .dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
    .dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter {
      color: $primary-color;
    }
    .dx-treelist .dx-link {
      color: $primary-color;
    }
    .dx-treelist-drop-highlight > td {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-treelist-focus-overlay {
      border: 2px solid $primary-color;
    }
    .dx-treelist-headers {
      color: $white-color;
      background-color: $primary-color !important;
    }
    .dx-treelist-filter-row .dx-menu-item.dx-state-focused:after {
      border: 2px solid $primary-color;
    }
    .dx-treelist-filter-range-overlay
      .dx-overlay-content
      .dx-texteditor.dx-state-focused:after {
      border: 2px solid $primary-color;
    }
    .dx-treelist-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
    .dx-treelist-rowsview
      .dx-row-focused.dx-data-row
      > tr
      > td:not(.dx-focused),
    .dx-treelist-rowsview
      .dx-row-focused.dx-data-row
      .dx-command-edit:not(.dx-focused)
      .dx-link {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-treelist-rowsview .dx-row-focused.dx-data-row > td,
    .dx-treelist-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
      border-bottom: 1px solid $primary-color;
    }
    .dx-treelist-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
    .dx-treelist-rowsview
      .dx-row-focused.dx-data-row:not(.dx-row-lines)
      > tr:first-child
      > td {
      border-top: 1px solid $primary-color;
      border-bottom: 1px solid $primary-color;
    }
    .dx-treelist-rowsview .dx-row-focused.dx-group-row {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-treelist-search-text {
      color: $white-color;
      background-color: $primary-color;
    }
    .dx-treelist-pager.dx-state-focused .dx-page:focus,
    .dx-treelist-pager.dx-state-focused .dx-page-size:focus,
    .dx-treelist-pager.dx-state-focused .dx-navigate-button:focus {
      outline: 2px solid $primary-color;
    }
    .dx-treelist-filter-panel .dx-icon-filter,
    .dx-treelist-filter-panel .dx-treelist-filter-panel-text,
    .dx-treelist-filter-panel .dx-treelist-filter-panel-clear-filter {
      color: $primary-color;
    }
    .dx-menu-item.dx-state-hover {
      background-color: $white-color;
    }
    .dx-menu-item.dx-state-focused {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-calendar-navigator
      .dx-calendar-caption-button.dx-button
      .dx-button-content {
      color: $primary-color;
    }
    .dx-calendar-navigator-previous-view.dx-button .dx-icon,
    .dx-calendar-navigator-previous-month.dx-button .dx-icon {
      color: $primary-color;
    }
    .dx-calendar-navigator-next-view.dx-button .dx-icon,
    .dx-calendar-navigator-next-month.dx-button .dx-icon {
      color: $primary-color;
    }
    .dx-treeview-item-with-checkbox.dx-state-focused
      > .dx-checkbox
      .dx-checkbox-icon {
      border: 1px solid $primary-color;
    }
    .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
      background-color: $primary-color;
      color: $white-color;
    }
    .dx-datagrid-table-fixed td.dx-datagrid-drag-action {
      background-color: $primary-color;
    }
    .dx-datagrid-table-fixed
      td.dx-command-expand.dx-cell-focus-disabled:not(.dx-datagrid-expand) {
      background-color: $primary-color;
    }
  }
}
