.tableSolicitacaoGrid
{
  width: 100%;
  margin-bottom:30px;
}

.tableSolicitacaoGrid th, .tableSolicitacaoGrid td {
  padding:5px;
  border:1px solid #ccc;
}

.tableSolicitacaoGrid thead, .tableSolicitacaoGrid tfoot
{
  background-color: #5c2e90;
  color: white;
}