@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.requisicoesLGPDFilters {

  .novaRequisicao {
    float: right;
  }

  max-height: calc(100vh - 112px);
  width: 100%;
  transition: all ease-in-out .3s;
  height: auto;

  @include device(smartphone) {
    height: auto;
    margin-bottom: 45px;
  }

  .filtersrWrapper {
    margin-bottom: 10px;
  }

  h1,
  h2,
  h3 {
    text-align: center;
  }

  hr {
    margin: 0 0 15px 0;
  }

  .buttonWrapper,
  .optionButtonWrapper {
    display: flex;
    justify-content: space-between;
    transition: all ease-in-out .3s;

    @include device(smartphone) {
      display: block;
    }

    .reportButton {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        height: 65px;
        width: 110px;
        font-size: 40px;
        display: flex;
        justify-content: center;

        i {
          position: relative;
          bottom: 10px;
        }
      }

      p {
        font-size: 14px;
        max-width: 121px;
        text-align: center;
        margin-bottom: 0;
      }

      button:first-of-type {
        margin-left: 0;
      }

      button:last-of-type {
        margin-right: 0;
      }

    }
  }

  .optionButtonWrapper {

    button {
      height: 55px !important;
      width: 90px !important;
      font-size: 36px !important;

      span {
        font-size: 22px;
      }
    }

    p {
      font-size: 12px !important;
      max-width: 83px !important;
    }
  }

  .preReportsWrapper {
    margin-top: 25px;
  }

  .appliedFilters {
    min-height: 45px;
    margin-bottom: 15px;
    display: flex;
    align-items: baseline;

    .pill {
      max-width: fit-content;
      border-radius: 9px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
      color: #FFFFFF;
      margin-right: 10px;
      height: 45px;
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
        font-size: 18px;
        cursor: pointer;
      }

      &.disabled {
        background-color: #EBEBEB;
        color: #000000;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .searchWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .dateWrapper {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 12px;
    }

    .datebox {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    span {
      font-size: 12px;
    }

    input {
      font-size: 14px;
    }

    .inputResults {
      min-height: 64px;
      border: 1px solid #D3D3D3;
      padding: 15px;
      z-index: 10;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      border-radius: 6px;

      .searchingWrapper {
        display: flex;
        justify-content: center;

      }

      .gridProdutos {
        max-height: calc(100vh - 646px);
      }

    }

    .produtosOverlay {
      position: fixed;
      top: 0;
      bottom: 0;
      height: 100%;
      z-index: 9;
      width: 100%;
      left: 0;
    }

    .noResults {
      display: flex;
      justify-content: center;
    }

  }

  .dropDownBox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    span,
    input {
      font-size: 12px;
    }

  }

  .searchOptions {
    border: 1px solid #D3D3D3;
    padding: 0 15px 15px 15px;
    position: relative;
    min-height: 380px;

    .filters {
      height: 303px;

      .nav {
        margin-bottom: 0;

        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 15px;

          .tab {
            padding: 2px 0px 5px 0px;
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            transition: all ease-in-out .2s;
            border-right: solid 1px #D3D3D3;
            border-left: solid 1px #D3D3D3;
            border-bottom: solid 1px #D3D3D3;
            background-color: #EBEBEB;
            transition: all ease-in-out .2s;

            &:last-of-type {
              border-right: unset;
            }

            &:first-of-type {
              border-left: unset;
            }

            span {
              font-size: 12px;

              i {
                font-size: 18px;
                margin-right: 4px;
              }
            }
          }
        }
      }

      .checkbox {

        label {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }





  footer {
    button {
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }

  &.expanded {
    height: auto;
  }

  &.search {
    height: auto;
  }

}

@keyframes in {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes out {
  0% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeIn {
  animation: in .6s ease 0s normal forwards 1;
  -webkit-animation: in .6s ease 0s normal forwards 1;
}

.fadeOut {
  -webkit-animation: out .3s ease 0s normal forwards 1 testeout;
  animation: out .3s ease 0s normal forwards 1 testeout;
  display: none !important;
}


.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  height: 1rem;
  margin-bottom: 4px;
  margin-right: 7px;

  & .path {
    stroke: $white-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
