@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

div {
  font-size: 14px !important;
}

.loader {
  margin: 20vh auto;
  display: flex;
  justify-content: center;
  flex-direction: column; 

  h1 {
    text-align: center;
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  height: 50px;
  margin-bottom: 4px;
  margin-right: 7px;

  & .path {
    stroke: $primary-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
