@import '../../../../../scss/linxCustom';
@import '../../../../../scss/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;

  .dataGridResult {
    .gridOptions {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      height: 5px;

      .expandButton {
        position: relative;
        right: 200px;
        top: 30px;
        z-index: 100;

        @include device(oldDesktop) {
          right: 200px;
        }
      }
    }
  }
}

h1 {
  color: $primary-color;
}


.gridColumnHeader {
  font-size: 12px !important;
}
