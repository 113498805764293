.tableSolicitacao
{
  width: 100%;
  margin-bottom:30px;
}

.tableSolicitacao th, .tableSolicitacao td {
  padding:5px;
  border:1px solid #ccc;
}

.tableSolicitacao thead, .tableSolicitacao tfoot
{
  background-color: #5c2e90;
  color: white;
}