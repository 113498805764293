@import '../../../scss/linxCustom';
@import '../../../scss/mixins';


.loader {
  position: fixed;
  margin: 20vh 41vw;
  text-align: center;

  @include device(notebook) {
    margin: 20vh 38vw;
  }
  
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  height: 50px;
  
  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

}


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
