@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.header {
  height: 60px;
  background-color: #fff;
  div: {
    height: 60px;
  }
}

.container > div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.textBodySecondary {
  color: rgba(33, 37, 41, 0.75) !important;
}

.table {
  table tbody[role='presentation'] tr td {
    font-size: 10pt;
  }
  table tbody[role='presentation'] tr td div[role='presentation'] {
    font-size: 10pt !important;
  }
}

.middle {
  td[role='gridcell'] {
    padding: 5px !important;
    vertical-align: middle !important;
  }
}

@keyframes in {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes out {
  0% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeIn {
  animation: in 0.6s ease 0s normal forwards 1;
  -webkit-animation: in 0.6s ease 0s normal forwards 1;
}

.fadeOut {
  -webkit-animation: out 0.3s ease 0s normal forwards 1 testeout;
  animation: out 0.3s ease 0s normal forwards 1 testeout;
  display: none !important;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  height: 1rem;
  margin-bottom: 4px;
  margin-right: 7px;

  & .path {
    stroke: $white-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.btnSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}
