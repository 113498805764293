@import '../../../../../scss/linxCustom';
@import '../../../../../scss/mixins';

.ReportManager {
  .check {
    margin-top: 23px;

    span {
      font-size: 12px;
    }
  }

  .dropDownBox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    span,
    input {
      font-size: 12px;
    }
  }

  .input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: 'Roboto';
    font-size: 12px;

    span,
    input {
      font-size: 12px !important;
      font-family: 'Roboto' !important;
    }

    h2 {
      text-align: left;
    }
  }

  footer {
    position: fixed;
    bottom: 16px;
    width: 95%;
    button {
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
}
