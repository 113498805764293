/*.table-ctn .input,
.form-input .input,
.form-input .DateInput_input *{
  font-size: medium;
  width: 100%;
  height: unset;
  padding: 0 0px;
}*/

.Informacao {
  color: #808080;
  position: relative;
  top: 20px;
  cursor: help;
}
