@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.InstagramButton {
  
  i {
    color: #9C80BB;
    font-size: 2rem;
  }
}
