@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.dataGridResult {
  font-family: 'Roboto';

  .gridOptions {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .pdfButton {
      position: absolute;
      right: 367px;
      top: 10px;
      z-index: 100;

      @include device(oldDesktop) {
        right: 310px;
      }

    }

    .expandButton {
      position: absolute;
      right: 409px;
      top: 10px;
      z-index: 100;

      @include device(oldDesktop) {
        right: 350px;
      }
    }

    .saveButton {
      position: absolute;
      right: 451px;
      top: 86px;
      z-index: 100;
    }
  }
}
