.left {
  width: 65%;
  float: left;
}

.right {
  width: 35%;
  float: left;
}

.modal-alteracao-pagamento {
  width: 70%;
}

.row-detail {
  flex: 1;
  display: flex;
  margin: 0 -10px;
  margin-bottom: -25px;
}

.title-detail {
  font-weight: bold;
  font-family: Roboto, sans-serif;
}

.help {
  position: absolute;
  right: 10px;
  color: #928ca3;
  font-size: 12px;
  background: #fff;
  padding: 4px 8px;
  transition: all ease 0.35s;
  font-weight: 500;
  border-radius: 3px;
}

.footer {
  box-sizing: border-box;
  padding: 0 25px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.buttonWrapper {
  padding-top: 27px;
}
