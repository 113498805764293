@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.subTitle {

  h1 {
    color: $primary-color;
  }

  span {
    font-style: italic;
    font-size: small;
  }

  margin-left: 1rem;

}

.noPadding {
  .dx-popup-content{
    padding: 0px !important;
  }
}

.input {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  span,
  input {
    font-size: 0.75rem;
  }

  h2 {
    text-align: left;
  }
}

.adesaoLoading{
  display: flex;
  justify-content: center;
  flex-direction: row;

  .adesaoLoadingText {
    display: grid;
    margin-top: 0.25rem;
    margin-left: 1rem;

    h1 {
      color: $primary-color;
    }
    p {
      margin-top: 0;
    }
  }
}

.spinner {
  animation: rotate 1s linear infinite;
  z-index: 2;
  height: 3.5rem;
  margin-bottom: 4px;
  margin-right: 7px;

  & .path {
    stroke: $primary-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
