.SelecionaEmpresa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .ContainerEmpresas {
    overflow: auto;
    overflow-x: hidden;
    margin-bottom: 10px;
  }

  button {
    align-self: flex-end;
  }
}
