@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.HelpShareButton {
  display: flex;
  
  .helpButton {
    width: 36px;
    height: 36px;
    margin-left: 5px;
    display: inline-flex;
    .help {
      text-align: center;
      font-size: 1.5rem;
      margin-left: 6px;
    }
  }
}
