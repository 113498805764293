@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.SearchProdutos {
  div {
    font-size: 12px !important;
  }
  
  input {
    font-size: 12px;
    height: 35px !important;
  }

  .input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    span {
      font-size: 12px;
    }

    input {
      font-size: 12px;
      height: 35px !important;
    }

    .produtosOverlay {
      position: fixed;
      top: 0;
      bottom: 0;
      height: 100%;
      z-index: 9;
      width: 100%;
      left: 0;
    }

    .noResults {
      display: flex;
      justify-content: center;
    }
  }

  .inputGroupTextIcon {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .inputResults {
    min-height: 64px;
    border: 1px solid #d3d3d3;
    padding: 15px;
    z-index: 10;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border-radius: 6px;

    .searchingWrapper {
      display: flex;
      justify-content: center;
    }
  }

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    height: 1rem;
    margin-bottom: 4px;
    margin-right: 7px;

    & .path {
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  .produtosOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: -1;
    width: 100%;
    left: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes in {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes out {
  0% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
