// faturamentoLoteModal.module.scss

.customModal .modal-dialog {
  max-width: 80%;  // Permite que o modal ocupe até 80% da largura da tela
  width: auto;
}

.customModal .modal-content {
  max-height: 80vh;  // Permite que o modal ocupe até 80% da altura da tela
  overflow: hidden;  // Evita overflow do conteúdo fora do modal
}

.tableWrapper {
  max-height: 60vh;  // Limita a altura da tabela para 60% da altura da tela
  overflow-y: auto;  // Adiciona scroll vertical se a tabela for maior que o contêiner
}

.label {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #fff;
}

.labelSuccess {
  background-color: #28a745;  // Verde
}

.labelError {
  background-color: #dc3545;  // Vermelho
}

.labelProcessing {
  background-color: #ffc107;  // Amarelo
}

.labelDefault {
  background-color: #6c757d;  // Cinza (default)
}
