@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.Login {
  .pageLogin {
    background-color: $white-color;

    picture {
      margin-bottom: 50px;
      display: block;
    }

    picture,
    .formLogin {
      width: 29%;
      margin-left: 150px;

      @include device(notebook) {
        width: 43%;
      }

      .controlLabel {
        font-size: 0.875rem;
        padding-right: 0;
      }
    }

    .authorizedCNPJs {
      width: 29%;
      margin-left: 150px;

      p {
        margin: 0;
        position: relative;
        bottom: 15px;
      }

      h3 {
        text-align: center;
      }

      .searchInput {
        margin-bottom: 5px;
      }

      .empresas {
        border: solid 1px #ededed;
        border-top: 0;
        margin-bottom: 6px;
        position: relative;

        // div {
        //   max-height: 201px;
        //   min-height: 201px;
        // }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        width: 500px;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
    }

    .customControlPosition {
      top: 5px;
    }

    .copyright {
      color: $white-color;
      font-size: 1rem;
      position: absolute;
      bottom: 0px;
    }

    .midiasSociais {
      position: absolute;
      left: 160px;
      width: 29%;
      display: inline-flex;
    }

    .linkSupport {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }

  .columnHeight {
    height: 100vh;
  }

  .textBrandLinx {
    font-size: 2.625rem;
    font-weight: 300;
    margin-bottom: 0;
  }

  .textBrandProduct {
    color: $white-color;
    font-size: 3rem;
    font-weight: 300;
  }

  .textBrandScreen {
    color: $white-color;
    font-size: 1.625rem;
    font-weight: 300;
  }

  .form-recover-password {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .form-recover-password h1 {
    font-size: 1.875rem;
    font-weight: 400;
  }

  .loginContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    background: #fff;
  }
}

.bgPrimary {
  background: url(../../../../assets/images/linx/header-background.jpg);
  background-position: top;
  background-size: cover;

  center {
    color: $white-color;
    font-size: 15px;
    line-height: 30px;
    font-weight: 600;
  }
}

.bgPrimaryEmporio {
  background: url(../../../../assets/images/linx/header-background.jpg);
  background-position: top;
  background-size: cover;

  center {
    color: $white-color;
    font-size: 15px;
    line-height: 30px;
    font-weight: 600;
  }
}

@include device(small-smartphone) {
  .pageLogin {
    picture,
    .formLogin {
      width: auto !important;
    }

    .linkSupport {
      display: none;
    }

    .formLogin {
      .controlLabel {
        font-size: 0.8rem;
      }
    }

    .textBrandLinx {
      font-size: 1.25rem;
    }

    .textBrandProduct {
      font-size: 1rem;
    }
  }
}

@include device(smartphone) {
  .pageLogin {
    picture,
    .formLogin {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .copyright {
      display: none;
    }

    .columnHeightA {
      height: 10vh;
    }

    .columnHeightB {
      height: 90vh;
    }
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  height: 1rem;
  margin-bottom: 4px;
  margin-right: 7px;

  & .path {
    stroke: $white-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
