@import '../../../../../scss/linxCustom';
@import '../../../../../scss/mixins';

.fonteDados {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 70%;

  h1 {
    color: $primary-color;
  }

  span {
    color: $primary-color;
    font-weight: bold;
    font-size: 12px;
    margin: 10px 0 15px 10px;
  }

  .fonteDadosOption {
    border: 2px solid $gray-color;
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 15px;
    background-color: $white-color;
    cursor: pointer;

    input {
      cursor: pointer;
    }

    label {
      padding: 10px 0 0 5px;
      font-family: 'Dosis', sans-serif;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
    }

    p {
      font-size: 0.75rem;
      margin: 0 0 10px 20px;
      padding-bottom: 5px;
    }
  }

  .fonteDadosOptionChecked {
    background-color: $light-color;
    margin-bottom: 0;
  }

  .fonteDadosOptionDownloadTemplate {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin: 40px 0 15px 10px;

    button {
      color: $primary-color;
      background-color: $white-color;
      -webkit-box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.15);
      margin-bottom: 25px;

      &:hover {
        background-color: $light-color;
      }

      &:active {
        background-color: $gray-color;
      }

      i {
        font-size: 1.3rem;
        padding-right: 10px;
      }
    }
  }
}
