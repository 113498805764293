.minW {
  min-width: 1000px !important;
}

.container > div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.header {
  height: 60px;
  background-color: #fff;
  div: {
    height: 60px;
  }
}

footer {
  margin-top: 30px;
  height: 112px;
  background-color: #fff;
  width: 100%;
  padding-left: 51px;
}

$emAberto: #ff8229;
$confirmado: #809911;
$carregado: #22b14c;
$entregue: #156f30;
$recolhido: #0505a2;
$recolhidoParcial: #644ad8;
$cancelado: #ed1e25;
$canceladoUnchecked: #ed1e2573;
$fechado: #0c0c0c;

.bg-em-aberto {
  color: #fff !important;
  background-color: $emAberto !important;
}
.color-em-aberto {
  color: $emAberto !important;
}
.bg-confirmado {
  color: #fff !important;
  background-color: $confirmado !important;
}
.color-confirmado {
  color: $confirmado !important;
}
.bg-carregado {
  color: #fff !important;
  background-color: $carregado !important;
}
.color-carregado {
  color: $carregado !important;
}
.bg-entregue {
  color: #fff !important;
  background-color: $entregue !important;
}
.color-entregue {
  color: $entregue !important;
}
.bg-recolhido {
  color: #fff !important;
  background-color: $recolhido !important;
}
.color-recolhido {
  color: $recolhido !important;
}
.bg-recolhido-parcial {
  color: #fff !important;
  background-color: $recolhidoParcial !important;
}
.color-recolhido-parcial {
  color: $recolhidoParcial !important;
}
.bg-cancelado {
  color: #fff !important;
  background-color: $cancelado !important;
}
.bg-cancelado-unchecked {
  color: #fff !important;
  background-color: $canceladoUnchecked !important;
}
.color-cancelado {
  color: $cancelado !important;
}
.bg-fechado {
  color: #fff !important;
  background-color: $fechado !important;
}
.color-fechado {
  color: $fechado !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-spin {
  animation: spin 1s linear infinite;
}
