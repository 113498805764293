@import '../../../../../scss/linxCustom';
@import '../../../../../scss/mixins';

.Conferido {
  background-color: $success-color;
}

.ParcialmenteConferido {
  background-color: $warning-color;
}

.NaoConferido {
  background-color: rgb(109, 109, 109);
}

.Aberto {
  background-color: $info-color-hover;
}

.SituacaoBadge {
  max-width: fit-content;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  color: #ffffff;
  margin-right: 10px;
  height: 25px;
  display: flex;
  align-items: center;
  margin-left: 1em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
