@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.ConfiguracoesGerais {
  
  .help {
    font-size: 1.5rem;
  }

  .pannelTitle {
    padding-top: 10px;
    font-size: 1.5rem;
  }
  .tabContent {
    padding: 20px 20px 20px 20px;
  }
}