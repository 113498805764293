@import '../../../../../scss/linxCustom';
@import '../../../../../scss/mixins';

.container {
  width: 100%;
  span {
    font-size: 0.75rem;
  }

  .textInfo {
    background-color: $secondary-color;
    border-radius: 5px;

    p {
      padding: 5px;
      margin: 5px;
      font-size: 0.75rem;
      color: $white-color;
    }
  }

  .dataGridResult {
    .gridOptions {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      height: 5px;

      .expandButton {
        position: relative;
        right: 200px;
        top: 30px;
        z-index: 100;

        @include device(oldDesktop) {
          right: 200px;
        }
      }
      .exportButton {
        position: relative;
        right: 400px;
        margin-right: 10px;
        top: 30px;
        z-index: 100;
        background-color: $primary-color;
        color: $white-color;

        span {
          font-size: 14px;
          font-weight: bold;
        }

        &:hover {
          background-color: $primary-color-hover;
        }

        &:active {
          background-color: $primary-color;
        }

        @include device(oldDesktop) {
          right: 200px;
        }
      }
    }
  }
}

.gridColumnHeader {
  font-size: 12px !important;
}
