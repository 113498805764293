.footer {
  line-height: 33px;
  text-align: center;
  height: 60px;
  width: 100%;
  padding: .5rem 1rem;
  box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, .075);
  z-index: 1;
  background-color: #f5f5f5;
  z-index: 1029 !important;

  button {
    margin: 0 1rem;

    &.btn {
      margin: 0 1rem;
    }
  }

  .saveButton {
    i {
      margin-right: 5px;
    }
  }

  .anonimizarButton {

    i {
      margin-right: 5px;
    }
  }

  .printButton {

    i {
      margin-right: 5px;
    }
  }

  .eliminarButton {

    i {
      margin-right: 5px;
    }
  }

  .concluirButton {

    i {
      margin-right: 5px;
    }
  }

  .quitarButton {

    i {
      margin-right: 5px;
    }
  }
}
