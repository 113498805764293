@import '../../scss/linxCustom';
@import '../../scss/mixins';

.wizardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .wizardHeaderItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .wizardHeaderItemLine {
      border: 1px solid $gray-color;
      width: 100%;
      margin-top: 20px;
    }

    .wizardHeaderItemLineFirst {
      border: 1px solid $gray-color;
      width: 50%;
      margin-top: 20px;
      position: relative;
      left: 25%;
    }

    .wizardHeaderItemLineLast {
      border: 1px solid $gray-color;
      width: 50%;
      margin-top: 20px;
      position: relative;
      right: 25%;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100px;

      position: relative;
      bottom: 25px;
    }

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1rem;

      background-color: $gray-color;
      color: $white-color;
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }

    .wizardHeaderItemActive {
      h1 {
        background-color: $secondary-color;
        border: 2px solid $white-color;
        padding: 5px;
        box-shadow: 0 0 0 2px $secondary-color;
      }
    }

    p {
      margin: 0;
      height: 50px;
    }
  }
}

.wizardBodyComponent {
  display: flex;
  justify-content: center;
}
