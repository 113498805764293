@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.lais {
  border-left: 1px solid #eeeeee;
  .navItem {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 7px;
    .Lais {
      img {
        height: 36px;
        width: 36px;
        cursor: pointer;
      }
    }
  }
}
