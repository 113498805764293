@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.guia {
    box-sizing: border-box;
    padding-right: 20px;
  }

.actions {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-bottom: 7px;
    margin-top: 9px;
    justify-content: flex-end;
    padding-right: 0px !important;

    .newLancamento {    
        height: 36px;
    }
  }



