@import '../../scss/linxCustom';
@import '../../scss/mixins';

.pill {
  max-width: fit-content;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  color: #FFFFFF;
  margin-right: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #EBEBEB;
  color: #000000;

  i {
    margin-right: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  span {
    font-size: 12px !important;
  }
}
