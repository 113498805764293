@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.dadoAdicionalResult {
  div {
    font-size: 14px !important;
  }

  .newDadoAdicional {
    float: right;
  }

  .exportExcel {
    button {
      margin-right: 5px;

      i {
        margin-right: 5px;
      }
    }
  }

  .appliedFilters {
    min-height: 45px;
    margin-bottom: 15px;
    display: flex;
    align-items: baseline;

    .pill {
      max-width: fit-content;
      border-radius: 9px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
      color: #ffffff;
      margin-right: 10px;
      height: 45px;
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
        font-size: 18px;
        cursor: pointer;
      }

      &.disabled {
        background-color: #ebebeb;
        color: #000000;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .results {
    min-height: fit-content;
    max-height: fit-content;
    transition: all ease-in-out 0.2s;
    margin-bottom: 40px;
  }
}
