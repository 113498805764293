@import '../../../scss/linxCustom';
@import '../../../scss/mixins';


.sidenav {
    overflow-x: hidden;
    height: 100%;
    width: 51px;
    position: fixed;
    z-index: 1033;
    top: 0;
    left: 0;

    @include device(smartphone) {
      display: none;
    }

    .sidenavBrand {
        text-align: center;
        width: 51px;
        padding: 25px 0 18px 0;
        margin-top: -8px;
        margin-bottom: -3px;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        color: rgba(0, 0, 0, .9);
    }

    .buttons {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li {
            span {
                font-size: 16px;

                &:focus,
                &:active,
                &:focus {
                    outline: 0;
                    background-color: transparent;
                }
            }
        }

        .navItem {

            .navLink {
                // margin-bottom: 13px;
                display: block;
                text-transform: capitalize;
                padding: 1rem .5rem .8rem .5rem;
                position: relative;
                cursor: pointer;

                .icon::before {
                    font-size: 2rem;
                }
            }

            .navLinkPlaceHolder {
                height: 74px;
            }
        }

        .navSideMenuItem {

            .navLink {
                // margin-bottom: 13px;
                display: block;
                text-transform: capitalize;
                padding: 9px;
                position: relative;
                cursor: pointer;

                .icon::before {
                    font-size: 2rem;
                }
            }

            .navLinkPlaceHolder {
                height: 51px;
            }
        }
    }
}

.menus {
    width: 300px;
    height: 100%;
    padding-bottom: 20px;
    z-index: 1032;
    position: fixed;
    top: 0;
    left: -351px;
    transition: all ease-in-out 0.3s;

    &.active {
        left: 51px;

        @include device(smartphone) {
            left: 0;
        }
    }

    .productName {
        height: 62px;
        padding: 16px;

        span {
            font-family: 'Dosis', sans-serif !important;
            font-size: 20px;
        }
    }

    .menu {
        list-style: none;
        cursor: pointer;
        user-select: none;
        margin-bottom: 0;
        transition: all ease-in-out 0.2s;
        
        .menuHeader {
            font-family: 'Dosis', sans-serif !important;

            .menuTitle {
                padding: 12px 16px 12px 21px;

                .arrow {
                    transition: all ease-in-out 0.2s;

                    &.rotate {
                        transform: rotate(90deg);
                    }
                }
            }

            span {
                margin-left: 16px;
            }

            &.expanded {
                
                .menuHeader {
                    font-weight: bold;
                }
            }
        }

        .menuChildren {
            transition: all ease-in-out 0.2s;
            height: 0;
            overflow: hidden;

            ul {
                list-style: none;
            }

            &.expanded {
                height: auto;
            }

            .children {
                padding: 6px 6px 6px 24px;

                .arrow {
                    transition: all ease-in-out 0.2s;
                    margin-right: 12px;


                    &.rotate {
                        transform: rotate(90deg);
                    }
                }
            }

            .menuChildrenChildren {

                a:last-child {

                    .childrenChildren {
                        padding-bottom: 5px;
                    }
                }

                .childrenChildren {
                    padding-left: 36px;
                    font-size: 14px;
                    font-weight: 700;

                }

                .menu3nivel {             
                    padding-left: 36px;
                }

                .menu4nivel {
                    padding-left: 30px;
                }

                .menu5nivel {
                    padding-left: 30px;
                }
            }            
        }
    }
}

.menuOverlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    background: rgba(0, 0, 0, .3);
}

.searchPanel {
    height: 100%;
    width: calc(100vw - 51px);
    position: absolute;
    left: -100vw;
    top: 0;
    z-index: 1032;
    transition: all ease-in-out .3s;

    &.active {
        left: 51px;

    }

    .searchPanelWrapper {
        padding: 0 80px;

        .lastSearched,
        .favorites {

            h2 {
                font-size: 26px;
            }

            span {
                border: 0;
                cursor: pointer;

                i {
                    font-size: 2rem;
                    vertical-align: sub;
                }
            }
        }

        .favorites {

            span {
                border: 0;
                color: #333333 !important;

                i {
                    font-size: 2rem;
                    vertical-align: sub;
                }

            }
        }
    }

    h1 {
        text-align: center;
        font-weight: 400;
        font-size: 38px;
        margin-top: 25px;
    }
}


@keyframes in {
    0% {
        opacity: 0;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes out {
    0% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeInMenu {
    animation: in .5s ease 0s normal forwards 1;
    -webkit-animation: in .5s ease 0s normal forwards 1;
}

.fadeOutMenu {
    -webkit-animation: out .5s ease 0s normal forwards 1 testeout;
    animation: out .5s ease 0s normal forwards 1 testeout;
    display: none !important;
}

.fadeInChildren {
    animation: in .4s ease 0s normal forwards 1;
    -webkit-animation: in .4s ease 0s normal forwards 1;
}

.fadeOutChildren {
    -webkit-animation: out .4s ease 0s normal forwards 1 testeout;
    animation: out .4s ease 0s normal forwards 1 testeout;
    display: none !important;
}

.fadeInSearchPanel {
    animation: in .5s ease 0s normal forwards 1;
    -webkit-animation: in .5s ease 0s normal forwards 1;
}

.fadeOutSearchPanel {
    -webkit-animation: out .5s ease 0s normal forwards 1 testeout;
    animation: out .5s ease 0s normal forwards 1 testeout;
    display: none !important;
}
