.cart {
  margin: 15px;
}
.table {
  td {
    vertical-align: middle !important;
    text-align: center;
  }
  th {
    text-align: center;
  }
}



