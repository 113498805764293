@import url('https://fonts.googleapis.com/css?family=Dosis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
  font-family: 'Dosis Bold';
  src: url(../../assets/fonts/Dosis/Dosis-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis ExtraBold';
  src: url(../../assets/fonts/Dosis/Dosis-ExtraBold.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Dosis ExtraLight';
  src: url(../../assets/fonts/Dosis/Dosis-ExtraLight.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Dosis Light';
  src: url(../../assets/fonts/Dosis/Dosis-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis Medium';
  src: url(../../assets/fonts/Dosis/Dosis-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis Regular';
  src: url(../../assets/fonts/Dosis/Dosis-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis SemiBold';
  src: url(../../assets/fonts/Dosis/Dosis-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansPro Bold';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansPro BoldItalic';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-BoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'NeoSansPro Italic';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-Italic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'NeoSansPro Light';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansPro LightItalic';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-LightItalic.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansPro Medium';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansPro Regular';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-Regular.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSansPro Ultra';
  src: url(../../assets/fonts/NeoSansPro/NeoSansPro-Ultra.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Black';
  src: url(../../assets/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto BlackItalic';
  src: url(../../assets/fonts/Roboto/Roboto-BlackItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url(../../assets/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto BoldItalic';
  src: url(../../assets/fonts/Roboto/Roboto-BoldItalic.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Italic';
  src: url(../../assets/fonts/Roboto/Roboto-Italic.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Light';
  src: url(../../assets/fonts/Roboto/Roboto-Light.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto LightItalic';
  src: url(../../assets/fonts/Roboto/Roboto-LightItalic.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url(../../assets/fonts/Roboto/Roboto-Medium.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto MediumItalic';
  src: url(../../assets/fonts/Roboto/Roboto-MediumItalic.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Regular';
  src: url(../../assets/fonts/Roboto/Roboto-Regular.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Thin';
  src: url(../../assets/fonts/Roboto/Roboto-Thin.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto ThinItalic';
  src: url(../../assets/fonts/Roboto/Roboto-ThinItalic.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "linx-icon";
  src:url("../../assets/fonts/LinxIcons/linx-icon.eot");
  src:url("../../assets/fonts/LinxIcons/linx-icon.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/LinxIcons/linx-icon.woff") format("woff"),
    url("../../assets/fonts/LinxIcons/linx-icon.ttf") format("truetype"),
    url("../../assets/fonts/LinxIcons/linx-icon.svg#linx-icon") format("svg");
    font-style: normal;
    font-weight: normal;

}
