@import '../../scss/linxCustom';
@import '../../scss/mixins';

.SellerPopFrame{    
    position: relative !important;
    height: calc(100vh - 87px) !important;
    width: 100% !important;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
    border: none;
    margin-top: 15px;
    
}
  