@import '../../../scss/linxCustom';

.reportButton {
  margin: 10px;

  .preReportButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 50px;
    //margin: 10px;
    display: flex;
    justify-content: center;
    min-width: 130px;
    min-height: 85px;
    border-radius: 3px;
    cursor: pointer;

    i {
      color: white;
      font-size: 40px;
    }
  }

  .dropMenu {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 300px;
    padding: 0 !important;

    .dropItem {
      cursor: pointer;
      font-size: 14px !important;
      font-family: 'Dosis' !important;
      padding-top: 10px;
      padding-bottom: 10px;

      &:not(:first-child) {
        border-top: 1px solid #e9ecef;
      }

      
    }
  }

  p {
    font-size: 14px !important;
    text-transform: uppercase !important;
    //max-width: 121px;
    color: white !important;
    text-align: center !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-family: 'Dosis' !important;
  }

  button:first-of-type {
    margin-left: 0;
  }

  button:last-of-type {
    margin-right: 0;
  }
}
