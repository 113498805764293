@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.helpOptions {
    border-left: 1px solid #EEEEEE;
    .navItem {
        .navLink {
            display: block;
            padding: .5rem 1rem;
            i {
                font-size: 28px;
                font-weight: 400;
            }
        }
    }
}