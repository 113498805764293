.label {
  font-size: small !important;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.checkbox {
  height: 15px !important;
}

.pLaceholder {
  font-size: small !important;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.bottonBorder {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.listaOs {
  table tbody[role='presentation'] tr td {
    font-size: 8pt;
  }
  table tbody[role='presentation'] tr td div[role='presentation'] {
    font-size: 8pt !important;
  }
}



.longText {
  text-overflow: ellipsis;
  overflow: hidden;
}
.smallText {
  font-size: 7pt !important;
}
.middle {
  td[role='gridcell'] {
    padding: 5px !important;
    vertical-align: middle !important;
  }
}

.floatTimeInputLabel {
  margin-top: -4px !important;
}
.floatTimeInput {
  min-width: 100px;
}

.table {
  tbody tr td {
    height: 25px !important;
    font-size: 11pt !important;
    vertical-align: middle !important;
  }
}
