@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.conferenciaCaixaResult {
  div {
    font-size: 14px !important;
  }

  .appliedFilters {
    min-height: 45px;
    margin-bottom: -25px;
    display: flex;
    align-items: baseline;

    .pill {
      max-width: fit-content;
      border-radius: 9px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
      color: #ffffff;
      margin-right: 10px;
      height: 45px;
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
        font-size: 18px;
        cursor: pointer;
      }

      &.disabled {
        background-color: #ebebeb;
        color: #000000;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .conferido {
    max-width: fit-content;
    border-radius: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    color: #ffffff;
    margin-right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: 1em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  .parcialmenteConferido {
    background-color: $warning-color;
    max-width: fit-content;
    border-radius: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    color: #ffffff;
    margin-right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: 1em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  .naoConferido {
    background-color: rgb(109, 109, 109);
    max-width: fit-content;
    border-radius: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    color: #ffffff;
    margin-right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: 1em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  .abertoNoPdv {
    background-color: $info-color-hover;
    max-width: fit-content;
    border-radius: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    color: #ffffff;
    margin-right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: 1em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .buttons > div {
    width: 180px;
    flex-wrap: nowrap;
    display: flex;
  }

  .buttonsColumn > div {
    width: 450px;
    height: 50px;
    text-align: center;
  }

  .buttonsColumn {
    width: 150px;
    justify-content: center;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
  }

  .card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    cursor: pointer;
  }

  .totais {
    justify-content: space-between;
    padding: 1em;
  }

  .card__header,
  .card__content {
    padding: 1rem;
  }

  .card__header h1,
  .card__content h1,
  .card__content h2,
  .card__content h3,
  .card__content p {
    margin: 0;
  }

  .card__header h1 {
    font-size: large;
  }

  .card__header h5 {
    font-size: medium;
    color: gray;
  }

  .card__content h3 {
    color: gray;
  }

  .cardItem {
    width: 23rem;
    margin: 0.7rem;
    max-width: 355px;
    max-height: 18rem;
  }

  .cardItem:hover {
    transition: 0.5s;

    h1 {
      color: rgb(212, 212, 212);
    }

    h3 {
      color: rgb(212, 212, 212);
    }

    h5 {
      color: #fff;
    }

    button {
      transition: 0.5s;
      background: #fff;
    }
  }

  .botaoAcoes {
    position: relative;
    //display: inline-block;
    float: right;
    height: 40px;

    button {
      height: 40px;
      width: 40px;
      font-size: 25px;
      display: flex;
      justify-content: center;
      color: white;
      border: 1px;
      border-radius: 10px;
      box-shadow: 0 4px 15px rgba(55, 1, 59, 0.822);
    }
  }

  .dropdownConf {
    position: absolute;
    top: 100%;
    left: 0;
    width: 145px;
    z-index: 10;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: rgba(255, 255, 255, 1);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }
  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }

  .botaoConf {
    border-radius: 9px;
    cursor: pointer;

    i {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .results {
    min-height: fit-content;
    max-height: fit-content;
    transition: all ease-in-out 0.2s;
    margin-bottom: 40px;
  }
}
