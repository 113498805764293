@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.movimentacaoEncerrantes{

    margin-bottom: 3rem;

    .results:first-of-type {
        margin-top: 0em !important;        
    }

    .results {
        margin-top: 1rem !important;        
    }

    .negativeCellText {
        color: red;
    }

    .iconButton {
        width: 18px;
        height: 18px;
        background-position: 0 0;
        background-size: 18px 18px;
        padding: 0;
        font-size: 18px;
        text-align: center;
        line-height: 18px;
        margin: 0 3px;
        cursor: pointer;
        display: inline-flex;
    }

}