@import './mixins';

* {
  padding: 0;
  margin: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

$themes: (
  green-theme: (
    primary-color: #07a26d,
    primary-color-hover: #038157,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  default-theme: (
    primary-color: #5b2e90,
    primary-color-hover: #2c004b,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  blue-theme: (
    primary-color: #41b1ef,
    primary-color-hover: #2980b9,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  yellow-theme: (
    primary-color: #ffc107,
    primary-color-hover: #ff9200,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  red-theme: (
    primary-color: #ea6153,
    primary-color-hover: #cd0a0a,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  ),
  br-theme: (
    primary-color: #397973,
    primary-color-hover: #1f5c52,
    secondary-color: #ffb200,
    secondary-color-hover: #ff9200,
    success-color: #34ab56,
    success-color-hover: #145d29,
    danger-color: #e53a20,
    danger-color-hover: #bc3025,
    warning-color: #ffc107,
    warning-color-hover: #e0a800,
    info-color: #5c9bdc,
    info-color-hover: #3b638c,
    light-color: #ebebeb,
    light-color-hover: #bdbdbd,
    dark-color: #343a40,
    dark-color-hover: #23272b,
    gray-color: #cccccc,
    white-color: #ffffff,
    navbar-a-color: #333333,
    navbar-color: #ffffff,
    widget-head-color: #424242,
    widget-body-color: #333333,
    breadcrumb-color: transparent
  )
);

$primary-color: map-get(
  $map: $themes,
  $key: primary-color
);
$primary-color-hover: map-get(
  $map: $themes,
  $key: primary-color-hover
);
$secondary-color: map-get(
  $map: $themes,
  $key: secondary-color
);
$secondary-color-hover: map-get(
  $map: $themes,
  $key: secondary-color-hover
);
$success-color: map-get(
  $map: $themes,
  $key: success-color
);
$success-color-hover: map-get(
  $map: $themes,
  $key: success-color-hover
);
$danger-color: map-get(
  $map: $themes,
  $key: danger-color
);
$danger-color-hover: map-get(
  $map: $themes,
  $key: danger-color-hover
);
$warning-color: map-get(
  $map: $themes,
  $key: warning-color
);
$warning-color-hover: map-get(
  $map: $themes,
  $key: warning-color-hover
);
$info-color: map-get(
  $map: $themes,
  $key: info-color
);
$info-color-hover: map-get(
  $map: $themes,
  $key: info-color-hover
);
$light-color: map-get(
  $map: $themes,
  $key: light-color
);
$light-color-hover: map-get(
  $map: $themes,
  $key: light-color-hover
);
$dark-color: map-get(
  $map: $themes,
  $key: dark-color
);
$dark-color-hover: map-get(
  $map: $themes,
  $key: dark-color-hover
);
$gray-color: map-get(
  $map: $themes,
  $key: gray-color
);
$white-color: map-get(
  $map: $themes,
  $key: white-color
);
$navbar-a-color: map-get(
  $map: $themes,
  $key: navbar-a-color
);
$navbar-color: map-get(
  $map: $themes,
  $key: white-color
);
$widget-head-color: map-get(
  $map: $themes,
  $key: widget-head-color
);
$widget-body-color: map-get(
  $map: $themes,
  $key: widget-body-color
);
$breadcrumb-color: transparent;

@mixin themify($property, $key) {
  @each $theme, $color in $themes {
    &.#{$theme} {
      #{$property}: map-get($color, $key);
    }
  }
}

@each $theme, $cor in $themes {
  $primary-color: map-get($cor, primary-color);
  $primary-color-hover: map-get($cor, primary-color-hover);
  $secondary-color: map-get($cor, secondary-color);
  $secondary-color-hover: map-get($cor, secondary-color-hover);
  $success-color: map-get($cor, success-color);
  $success-color-hover: map-get($cor, success-color-hover);
  $danger-color: map-get($cor, danger-color);
  $danger-color-hover: map-get($cor, danger-color-hover);
  $warning-color: map-get($cor, warning-color);
  $warning-color-hover: map-get($cor, warning-color-hover);
  $info-color: map-get($cor, info-color);
  $info-color-hover: map-get($cor, info-color-hover);
  $light-color: map-get($cor, light-color);
  $light-color-hover: map-get($cor, light-color-hover);
  $dark-color: map-get($cor, dark-color);
  $dark-color-hover: map-get($cor, dark-color-hover);
  $gray-color: map-get($cor, gray-color);
  $white-color: map-get($cor, white-color);
  $breadcrumb-color: map-get($cor, breadcrumb-color);
  $navbar-color: map-get($cor, navbar-color);
  $navbar-a-color: map-get($cor, navbar-a-color);
  $widget-head-color: map-get($cor, widget-head-color);
  $widget-body-color: map-get($cor, widget-body-color);

  .#{$theme} {
    a {
      color: $primary-color;
      text-decoration: none;
    }

    p {
      margin-bottom: 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: Dosis, sans-serif;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    h1 {
      font-size: 1.25rem;
    }

    h2 {
      font-size: 1.125rem;
    }

    h3 {
      font-size: 1rem;
    }

    div,
    label,
    p,
    span {
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 1rem;
    }

    .highlight-scroll {
      overflow: auto;
    }
    .help-link {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }

    .pt-4,
    .py-4 {
      padding-top: 1.5rem !important;
    }
    .display-2 {
      font-size: 5.5rem;
    }
    .display-1,
    .display-2 {
      font-weight: 300;
      line-height: 1.2;
    }

    .help-item__title-primary {
      color: #424242;
      font-size: 2.125rem;
    }

    .help-item .card-panel i.circle {
      display: inline-block;
      color: $primary-color;
      font-size: 28px;
      height: 62px;
      width: 62px;
      border-width: 2px !important;
      border-color: $primary-color !important;
      transform: translateY(50%);
    }

    .help-item .card-panel i.circle::before {
      position: relative;
      left: 15px;
      top: 7px;
    }

    .help-item__title {
      font-size: 1.625rem;

      @include device(smartphone) {
        font-size: 16px;
      }
    }

    .text-weight-light {
      font-weight: 400 !important;
    }

    .text-weight-medium {
      font-weight: 600 !important;
    }

    .line {
      height: 100%;
      width: 1px;
      border-right: 1px solid #e0e0e0;
      margin-bottom: 0;
      margin: 0 auto;
    }

    .list-group-item-action:hover,
    .list-group-item-action:focus {
      z-index: 0;
    }

    i.icon + .help-item__title,
    .help-item__title + .text-description {
      padding-left: 70px;
    }

    .highlight-scroll::-webkit-scrollbar-track {
      background-color: $primary-color;
    }

    .highlight-scroll::-webkit-scrollbar {
      width: 15px;
    }

    .highlight-scroll::-webkit-scrollbar-thumb {
      background: $primary-color-hover;
    }

    .custom-checkbox .custom-control-label:before {
      border-radius: 0.25rem;
    }

    .custom-control-label:before {
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: #dee2e6;
    }

    .custom-control-label:after,
    .custom-control-label:before {
      position: absolute;
      top: 0.1rem;
      left: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      content: '';
    }

    .custom-control-label:after {
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 50% 50%;
    }

    .custom-radio .custom-control-label:after,
    .custom-radio .custom-control-label:before {
      border-radius: 50%;
    }

    .custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label:before,
    .custom-control-input:checked ~ .custom-control-label:before,
    .custom-radio .custom-control-input:checked ~ .custom-control-label:before {
      background-color: $primary-color;
    }

    .custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }

    .text-uppercase {
      text-transform: uppercase;
    }

    .text-primary {
      color: $primary-color !important;
    }

    .text-secondary {
      color: $secondary-color;
    }

    .text-success {
      color: $success-color;
    }

    .text-danger {
      color: $danger-color;
    }

    .text-warning {
      color: $warning-color;
    }

    .text-info {
      color: $info-color;
    }

    .text-light {
      color: $light-color;
    }

    .text-dark {
      color: $dark-color;
    }

    .text-gray {
      color: $gray-color;
    }

    .btn-block {
      width: 100%;
    }

    button + .dropdown {
      display: inline-block;
    }

    .alert {
      border: 1px solid transparent;
      padding: 0.75rem 1.25rem;
      position: relative;
    }

    .alert-primary {
      color: $white-color;
      background-color: $primary-color;
      border-color: $primary-color-hover;
    }

    .alert-secondary {
      color: $white-color;
      background-color: $secondary-color;
      border-color: $secondary-color-hover;
    }

    .alert-success {
      color: $white-color;
      background-color: $success-color;
      border-color: $success-color-hover;
    }

    .alert-danger {
      color: $white-color;
      background-color: $danger-color;
      border-color: $danger-color-hover;
    }

    .alert-warning {
      color: $white-color;
      background-color: $warning-color;
      border-color: $warning-color-hover;
    }

    .alert-info {
      color: $white-color;
      background-color: $info-color;
      border-color: $info-color-hover;
    }

    .alert-light {
      color: $dark-color-hover;
      background-color: $light-color;
      border-color: $light-color-hover;
    }

    .alert-dark {
      color: $white-color;
      background-color: $dark-color;
      border-color: $dark-color-hover;
    }

    .breadcrumb {
      font-family: Roboto, sans-serif;
      font-size: 0.8125rem;
      font-weight: 400;
      background: $breadcrumb-color;
    }

    .breadcrumb li a {
      color: #000;
    }

    .breadcrumb li.active a,
    .btn {
      font-weight: 700;
    }

    .btn {
      display: inline-block;
      box-sizing: border-box;
      font-family: Dosis, sans-serif;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      border-radius: 0;
      border: 0;
      outline: 0;
      padding: 0.375rem 0.75rem;
    }

    .btn-margin {
      margin: 0px 5px;
    }

    .btn-outline-danger,
    .btn-outline-dark,
    .btn-outline-info,
    .btn-outline-light,
    .btn-outline-primary,
    .btn-outline-secondary,
    .btn-outline-success,
    .btn-outline-warning {
      border: 1px solid;
      background-color: transparent;
    }

    .btn-primary {
      color: #fff;
      background-color: $primary-color;
    }

    .btn-primary:disabled {
      background-color: lighten($primary-color, 20%);
    }

    .btn-light:disabled {
      background-color: lighten($light-color, 5%);
    }

    .btn-secondary {
      color: #fff;
      background-color: $secondary-color;
    }

    .btn-secondary:disabled {
      color: #fff;
      background-color: lighten($secondary-color, 20%);
    }

    .btn-success {
      color: #fff;
      background-color: $success-color;
    }

    .btn-danger {
      color: #fff;
      background-color: $danger-color;
    }

    .btn-warning {
      color: #333;
      background-color: $warning-color;
    }

    .btn-info {
      color: #fff;
      background-color: $info-color;
    }

    .btn-light {
      color: #333;
      background-color: $light-color;
    }

    .btn-dark {
      color: #fff;
      background-color: $dark-color;
    }

    .btn-link {
      color: #333;
      background-color: transparent;
    }

    .btn-outline-primary {
      color: $primary-color;
      border-color: $primary-color;
    }

    .btn-outline-secondary {
      color: $secondary-color;
      border-color: $secondary-color;
    }

    .btn-outline-success {
      color: $success-color;
      border-color: $success-color;
    }

    .btn-outline-danger {
      color: $danger-color;
      border-color: $danger-color;
    }

    .btn-outline-warning {
      color: $warning-color;
      border-color: $warning-color;
    }

    .btn-outline-info {
      color: $info-color;
      border-color: $info-color;
    }

    .btn-outline-light {
      border-color: $light-color;
    }

    .btn-outline-dark {
      color: $dark-color;
      border-color: $dark-color;
    }

    // .card {
    //   display: -ms-flexbox;
    //   display: flex;
    //   -ms-flex-direction: column;
    //   flex-direction: column;
    //   position: relative;
    //   min-width: 0;
    //   word-wrap: break-word;
    //   border: 1px solid rgba(0, 0, 0, 0.125);
    //   border-radius: 0.25rem;
    //   background-color: #fff;
    //   background-clip: border-box;
    // }

    // .card-img-top {
    //   width: 100%;
    //   border-top-left-radius: calc(0.25rem - 1px);
    //   border-top-right-radius: calc(0.25rem - 1px);
    // }

    // .card-body {
    //   -ms-flex: 1 1 auto;
    //   flex: 1 1 auto;
    //   padding: 1.25rem;
    // }

    // .card-title {
    //   margin-bottom: 0.75rem;
    // }

    .circle i.icon {
      position: relative;
      top: 3px;
    }

    .actions-list,
    .icon-lx-grid-2 {
      display: inline-block;
      font-size: 2rem;
    }

    label {
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    input {
      box-sizing: border-box;
      font-size: 12px;
      font-family: Roboto, sans-serif;
      font-weight: 300;
      border-radius: 0;
      margin: 0;
    }

    .input-group {
      position: relative;
      display: flex;
       flex-wrap: nowrap;
       align-items: stretch;
      width: 100%;
    }

    .input-group-text {
      background: transparent;
    }

    .dropdown-toggle::after {
      content: normal;
    }

    .menu-header {
      font-family: 'Dosis', sans-serif !important;
    }

    .form-group {
      margin-bottom: 1.563rem;
    }

    .form-control {
      display: block;
      height: 2.5rem;
      width: 100%;
      border: 1px solid $gray-color;
      padding: 0.375rem 0.75rem;
      border-radius: 0;
    }

    .custom-control {
      display: block;
      min-height: 1.5rem;
      padding-left: 1.5rem;
      position: relative;
    }

    .custom-control-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .input-required:after {
      content: ' *';
    }

    .icon-lx-notification::after {
      content: ' ';
      border-radius: 100%;
      padding: 5px;
      position: absolute;
      top: 5px;
      right: 10px;
      background: #f35958;
    }

    .navbar-lx {
      display: -ms-flexbox;
      display: flex;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      font-family: Dosis, sans-serif;
      font-weight: 400;
      position: relative;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      background-color: $navbar-color;
    }

    .navbar-lx a {
      color: $navbar-a-color;
    }

    .navbar-lx .nav-link {
      display: block;
      padding: 0.5rem 1rem;
    }

    .navbar-brand-lx {
      display: inline-block;
      border-right: 1px solid #ccc;
      padding-top: 0.3125rem;
      padding-right: 1rem;
      padding-bottom: 0.3125rem;
      margin-right: 1rem;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap;
    }

    .nav-lx {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .nav-lx,
    .pagination {
      display: -ms-flexbox;
      display: flex;
      padding-left: 0;
      list-style: none;
    }

    .pagination {
      font-family: Roboto, sans-serif;
      font-weight: 400;
    }

    .page-link {
      display: block;
      color: #000;
      line-height: 1.25;
      position: relative;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      background-color: transparent;
    }

    .widget {
      padding: 1rem;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: $white-color;
    }

    .widget,
    .widget__head {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .widget__head {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      position: relative;
      min-height: 30px;
      margin-bottom: 1rem;
    }

    .widget__head-label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: flex-first;
      align-content: flex-first;
    }

    .widget__head-title {
      color: $widget-head-color;
      margin-bottom: 0;
    }

    .widget__head-toolbar {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: end;
      align-content: flex-end;
    }

    .widget__body,
    .widget__head-toolbar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .widget__body {
      color: $widget-body-color;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    .d-inline-block {
      display: inline-block !important;
    }

    .align-top {
      vertical-align: top !important;
    }

    .shadow {
      transition: box-shadow 0.7s ease;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .shadow:hover {
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }

    .btn:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    .btn:disabled {
      cursor: not-allowed;
    }

    .btn-primary.focus,
    .btn-primary:active,
    .btn-primary:focus,
    .btn-primary:hover:not(:disabled) {
      background-color: $primary-color-hover;
    }

    .btn-secondary.focus,
    .btn-secondary:active,
    .btn-secondary:focus,
    .btn-secondary:hover:not(:disabled) {
      background-color: $secondary-color-hover;
    }

    .btn-seconday:disabled:hover {
      background-color: $secondary-color;
    }

    .btn-success.focus,
    .btn-success:active,
    .btn-success:focus,
    .btn-success:hover:not(:disabled) {
      background-color: $success-color-hover;
    }

    .btn-danger.focus,
    .btn-danger:active,
    .btn-danger:focus,
    .btn-danger:hover:not(:disabled) {
      background-color: $danger-color-hover;
    }

    .btn-warning.focus,
    .btn-warning:active,
    .btn-warning:focus,
    .btn-warning:hover:not(:disabled) {
      background-color: $warning-color-hover;
    }

    .btn-info.focus,
    .btn-info:active,
    .btn-info:focus,
    .btn-info:hover:not(:disabled) {
      background-color: $info-color-hover;
    }

    .btn-light.focus,
    .btn-light:active,
    .btn-light:focus,
    .btn-light:hover:not(:disabled) {
      background-color: $light-color-hover;
    }

    .btn-dark.focus,
    .btn-dark:active,
    .btn-dark:focus,
    .btn-dark:hover:not(:disabled) {
      background-color: $dark-color-hover;
    }

    .btn-link.focus,
    .btn-link:active,
    .btn-link:focus,
    .btn-link:hover:not(:disabled) {
      text-decoration: underline;
    }

    .btn-outline-primary.focus,
    .btn-outline-primary:active,
    .btn-outline-primary:focus,
    .btn-outline-primary:hover:not(:disabled) {
      color: #fff;
      background-color: $primary-color;
    }

    .btn-outline-secondary.focus,
    .btn-outline-secondary:active,
    .btn-outline-secondary:focus,
    .btn-outline-secondary:hover:not(:disabled) {
      color: #fff;
      background-color: $secondary-color;
    }

    .btn-outline-success.focus,
    .btn-outline-success:active,
    .btn-outline-success:focus,
    .btn-outline-success:hover:not(:disabled) {
      color: #fff;
      background-color: $success-color;
    }

    .btn-outline-danger.focus,
    .btn-outline-danger:active,
    .btn-outline-danger:focus,
    .btn-outline-danger:hover:not(:disabled) {
      color: #fff;
      background-color: $danger-color;
    }

    .btn-outline-warning.focus,
    .btn-outline-warning:active,
    .btn-outline-warning:focus,
    .btn-outline-warning:hover:not(:disabled) {
      color: #333;
      background-color: $warning-color;
    }

    .btn-outline-info.focus,
    .btn-outline-info:active,
    .btn-outline-info:focus,
    .btn-outline-info:hover:not(:disabled) {
      color: #fff;
      background-color: $info-color;
    }

    .btn-outline-light.focus,
    .btn-outline-light:active,
    .btn-outline-light:focus,
    .btn-outline-light:hover:not(:disabled) {
      color: #333;
      background-color: $light-color;
    }

    .btn-outline-dark.focus,
    .btn-outline-dark:active,
    .btn-outline-dark:focus,
    .btn-outline-dark:hover:not(:disabled) {
      color: #fff;
      background-color: $dark-color;
    }

    .btn-small {
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
    }

    .btn-lg {
      font-size: 1.25rem;
      padding: 0.5rem 1rem;
    }

    .form-control.is-invalid {
      border-color: $danger-color;
    }

    .invalid-feedback {
      color: $danger-color;
      font-size: 0.875rem;
      width: 100%;
      margin-top: 0.25rem;
    }

    input::placeholder {
      color: #000;
    }

    .form-control:focus {
      border-color: $primary-color;
      outline: 0;
      box-shadow: unset;
    }

    .form-control:disabled,
    .form-control[readonly] {
      border: 1px solid #e0e0e0;
    }

    .page-item.active .page-link {
      font-family: Roboto, sans-serif;
      font-weight: 700;
      border-color: transparent;
      z-index: 1;
      background-color: transparent;
    }

    .page-item:first-child .page-link,
    .page-item:last-child .page-link {
      color: $primary-color;
      font-size: 0.875rem;
    }

    .custom-range::-webkit-slider-thumb,
    .custom-range::-webkit-slider-thumb:active {
      background-color: $primary-color;
    }

    .custom-range::-webkit-slider-thumb:active {
      opacity: 0.25;
    }

    .table-actions {
      cursor: pointer;
    }
  }
}
