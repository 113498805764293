@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: $white-color;
  height: 62px !important;
  z-index: 1029 !important;

  @include device(smartphone) {
    margin-left: 0;
    color: $white-color;
  }

  &.topnavMenu {
    margin-left: 0px;
  }

  &.sidenavMenu {
    margin-left: 51px;
  }

  .headerWrapper {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      span {
        color: #333333;
        font-size: 16px;
        font-family: 'Dosis', sans-serif;
        cursor: pointer;

        @include device(smartphone) {
          color: $white-color;
        }

        &:active,
        &:focus {
          outline: 0;
          color: #333333;
          background-color: transparent;
        }

        i {
          font-size: 1.5rem;

          &.profile {
            margin-right: 3px;
          }
        }

        &.mobileMenu {
          color: #333333;
        }
      }
    }

    &.txtBrand {
      li {
        span {
          font-size: 20px;
        }
      }
    }

    .navItem {
      .organization {
        border-right: 1px solid $gray-color;

        @include device(smartphone) {
          margin: 0 10px 0px 16px;
          padding-bottom: 6px !important;
          padding-right: 16px !important;
        }
      }

      .organization {
        font-family: 'Dosis', sans-serif;
        padding: 8px;
        margin-bottom: 15px;
      }

      .Lais {
        img {
          height: 36px;
          width: 36px;
          cursor: pointer;
        }
      }

      .navLink {
        display: block;
        padding: 0.5rem 1rem;
      }

      .dropdownOverlay {
        z-index: 999;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
      }

      .dropdownMenu {
        overflow-y: auto;
        max-width: 25rem;
        min-width: 25rem;
        max-height: 590px;

        @include device(smartphone) {
          max-width: 16rem;
          min-width: 16rem;
        }

        .notificationFooter {
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
          background-color: $white-color;
          border-top: 1px solid rgba(0, 0, 0, 0.15);
          padding: 5px;

          @include device(smartphone) {
            display: none;
          }
        }
      }
    }

    .breadCrumbWrapper {
      margin-left: 15px;
      text-align: center;
      display: flex !important;

      @include device(smartphone) {
        display: none !important;
      }

      .breadcrumb {
        padding-top: 4px;
      }

      ul {
        list-style: none;
        display: inline-table;

        li {
          display: inline;

          &:first-child span {
            padding-left: 15px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            i {
              margin-right: 5px;
            }
          }

          &:first-child span:before {
            display: none;
          }

          &:last-child span {
            padding-right: 20px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          &:last-child span:after {
            display: none;
          }

          span {
            display: block;
            float: left;
            height: 40px;
            background: #eeeeee;

            text-align: center;
            padding: 0px 10px 0 30px;
            position: relative;
            margin: 0 10px 0 0;

            font-size: 18px;
            text-decoration: none;
            cursor: initial;

            &:after {
              content: '';
              border-top: 20px solid transparent;
              border-bottom: 20px solid transparent;
              border-left: 20px solid #eeeeee;
              position: absolute;
              right: -20px;
              top: 0;
              z-index: 1;
            }

            &:before {
              content: '';
              border-top: 20px solid transparent;
              border-bottom: 20px solid transparent;
              border-left: 20px solid #fff;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }
    }

    .product {
      background-color: #eeeeee;
    }

    &.textActions {
      li {
        span {
          font-size: 15px;
          font-weight: 400;
        }

        &:nth-child(2) {
          border-right: 1px solid #eeeeee;
        }
      }
    }
  }
}
