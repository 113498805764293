* {
  font-family: 'Dosis', sans-serif;
  text-rendering: optimizeLegibility;

  &:active,
  :focus {
    outline: none !important;
  }
}

td, tr {
  font-family: 'Roboto';
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: #F1F3F6;
  overflow: hidden;
}

label {
  margin-bottom: 0;
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

a:hover {
  text-decoration: none;
  color: inherit;
}
