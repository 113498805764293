@import '../../../../../scss/linxCustom';
@import '../../../../../scss/mixins';

.container {
  width: 100%;

  .gridTitle {
    border-radius: 5px;
    width: 400px;

    p {
      padding: 5px;
      margin: 5px;
      font-size: 0.75rem;
      color: $white-color;
    }
  }

  .bgBlue {
    background-color: $info-color;
  }

  .bgRed {
    background-color: $danger-color;
  }

  .bgGreen {
    background-color: $success-color;
  }

  h1 {
    color: $primary-color;
  }

  .subTitle {
    font-style: italic;
    font-size: smaller;
  }
}

.gridColumnHeader {
  font-size: 12px !important;
}
