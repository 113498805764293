.Maquinas {
  .itemField {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    span,
    input {
      font-size: 12px;
    }
  }

  .subPanelTitle {
    padding-top: 10px;
    font-size: 1rem;
  }

  .pannelTitle {
    padding-top: 10px;
    font-size: 1.5rem;
  }

  .tabContent {
    padding: 20px;
  }

  .rowSection {
    margin-top: 25px !important;
    margin-bottom: -25px !important;
  }
}
