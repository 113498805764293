.dropDownButton {
  width: 100%;
  margin-top: 10px;
  height: 60px;
}

.currency input{
  text-align: right;
  font-weight: bold !important;
  font-size: 20px !important;

}

.botton{
  margin-top: 30px;
  h1{
    font-size: 30px !important;
    font-weight: bold;
  }
  h2{
    font-size: 40px !important;
    font-weight: bold;
  }
}
