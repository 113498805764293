.menu {
  .dx-list-item-content {
    font-size: large;
  }
}

.paddingFooter {
  padding: 15px 30px !important;
  background-color: white;
}

.mainFooter {
  width: 100%;
  padding-left: 30px;
}

.mainRow {
  background-color: white;
}
.mainCol {
  background-color: white;
  border-left: 1px solid #ccc;
}

.fullHeight {
  height: 100%;
}

.container > div {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.mainCard {
  height: 800px !important;
  width: 960px !important;
}

.nav-link .active{
  background-color: #f8f9fa !important;
}
