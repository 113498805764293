.leftVBar{
    border-left: 1px solid #ccc;
}

.mainCol{
  background-color: white;
  height: 100%;
  padding: 15px 15px;
}

.mainRow{
  margin-top: 0px !important;
  margin-bottom: 2px !important;
  padding-top: 20px;
  padding: 20px 20px;
  background-color: white;
}
