@import '../../../../../scss/linxCustom';

.EmpresaCard {
  
  border-radius: 7px;
  border: solid 0.5px #cccccc;
  
  cursor: pointer;
  margin-bottom: 5px;
  align-self: flex-start;
  padding: 10px 10px;

  .CardItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
