@import '../../scss/linxCustom';
@import '../../scss/mixins';

.container {

  .fileUploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  
    border: 2px dashed rgba(91, 46, 144, 0.3);
    border-radius: 10px;
    height: 200px;
    width: 350px;
    margin-top: 15px;

    h1 {
      color: $primary-color;
      font-weight: bold;
    }
  
    span {
      color: $primary-color;
      font-weight: bold;
      font-size: 0.875rem;
    }
  
    .fileUploadButton {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      i {
        font-size: 1.3rem;
        padding-right: 10px;
      }
  
      span {
        font-size: 0.75rem;
        font-weight: 400;
        font-style: italic;
      }
    }
  }

  .fileUploadContainerDragEnter {
    border: 2px solid $primary-color;
    background-color: rgba(215, 215, 215, 0.1);
  }
  
  .fileDisplayContainer {
    display: flex;
    flex-direction: column;

    width: 350px;
    
    .fileStatusBar{
      width: 100%;
      vertical-align:top;
      margin-top: 10px;
      line-height: 50px;
      height: 50px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
  
      div {
        overflow: hidden;
      }
  
      .fileType {
        display: inline-block!important;
        position: absolute;
        font-size: 8px !important;
        font-weight: 700;
        line-height: 13px;
        margin-top: 15px;
        padding: 0 4px;
        border-radius: 2px;
        box-shadow: 1px 1px 2px #abc;
        color: #fff;
        background: #0080c8;
        text-transform: uppercase;
      }
  
      .fileName {
        display: inline-block;
        vertical-align:top;
        margin-left: 35px;
        font-size: 0.625rem;
        color: $dark-color;
      }
  
      .fileError {
        display: inline-block;
        vertical-align: top;
        margin-left: 45px;
        color: #9aa9bb;
      }
  
      .fileTypeLogo {
        width: 30px;
        height: 30px;
        background: url(../../../assets/images/postopop/generic-file.png) no-repeat center center; 
        background-size: 100%;
        position: absolute;
        margin-top: 5px;
      }
  
      .fileSize {
        display:inline-block;
        vertical-align:top;
        color:#30693D;
        margin-left:5px;
        margin-right:5px;
        color: #444242;
        font-weight: 700;
        font-size: 0.625rem;
      }
  
      .fileRemove  {
        display:inline-block;
        vertical-align:top;
        cursor: pointer;
        color: red;
      }
  
    }
  }  
}

