body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background: #f7f8f9;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  // overflow-y: auto;
}

.body-content {
  display: flex;
  margin: 0;
}

.body-container {
  width: 100px;
  margin-left: 220px;
  widows: 100%;
  flex: 1;
}

a,
a:active {
  text-decoration: none;
}

.login-logo {
  position: fixed;
  top: 30px;
  right: 30px;
  width: 100px;
  z-index: 9;
}

/* ==========================================================================
 Sidebar Navgation
 ========================================================================== */

.sidebar-nav-mobile {
  display: none;
}

.sidebar-nav {
  width: 220px;
  height: 100vh;
  background: linear-gradient(
    140deg,
    rgba(105, 53, 161, 1) 0%,
    rgba(91, 46, 144, 1) 65%,
    rgba(91, 46, 144, 1) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.sidebar-nav-head {
  width: 100%;
  height: 108px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sidebar-nav-head-content {
  display: none;
}

.sidebar-nav-list {
  width: 100%;
  margin: 0;
  padding: 0;
}

.sidebar-nav-item {
  display: flex;
  height: 45px;
  align-items: center;
  transition: all ease 0.25s;
  color: #fff;
  font-size: 13px;
  margin: 5px 0;
  opacity: 0.6;
  cursor: pointer;
  position: relative;
}

.sidebar-nav-item:first-child {
  margin-top: 0;
}

.sidebar-nav-item:before {
  content: '';
  width: 2px;
  height: 100%;
  background: #ff9200;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 1px 1px 10px 0px rgba(255, 146, 0, 0.85);
  transition: all ease 0.25s;
  transform: translateX(10px);
  opacity: 0;
}

.sidebar-nav-item.active,
.sidebar-nav-item:hover {
  background: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.sidebar-nav-item.active:before,
.sidebar-nav-item:hover:before {
  transform: translateX(0px);
  opacity: 1;
}

.sidebar-nav-item > .ic {
  margin: 0 20px;
  transition: all ease 0.25s;
}

.sidebar-nav-list.active .sidebar-nav-item > .ic {
  margin: 0 20px 0 13px;
}

.sidebar-nav-subnivel {
  position: absolute;
  top: 110px;
  left: 48px;
  right: 0;
  bottom: 0;
  background: #4a2677;
  transition: all ease 0.35s;
  opacity: 0;
  transform: translateX(100px);
  visibility: hidden;
  box-shadow: -1px 0px 10px -2px rgba(0, 0, 0, 0.15);
}

.sidebar-nav-subnivel.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}

.sidebar-nav-subnivel-list {
  transition: all ease 0.35s;
  opacity: 0;
  transform: translateX(30px);
  visibility: hidden;
  position: absolute;
  list-style: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.sidebar-nav-subnivel-list.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
  transition-delay: 0.15s;
}

.sidebar-nav-subnivel-item {
  height: 37px;
}

.sidebar-nav-subnivel-item-head,
.sidebar-nav-subnivel-item > a {
  padding: 0px 15px;
  height: 37px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #fff;
  font-size: 12px;
  transition: all ease 0.25s;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.sidebar-nav-subnivel-item > a {
  border-bottom: solid 1px rgba(0, 0, 0, 0.35);
}

.sidebar-nav-subnivel-item > a:hover {
  padding-left: 30px;
  background: rgba(255, 255, 255, 0.05);
}

.sidebar-nav-subnivel-item-head {
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: rgba(0, 0, 0, 0.35);
}

.sidebar-nav-subnivel-item-head > span {
  margin-left: 10px;
}

.sidebar-nav-subnivel-item.subnivel > a:before {
  content: '';
  width: 10px;
  height: 6px;
  position: absolute;
  right: 15px;
  top: 20px;
  bottom: 0;
  background: url('../../assets/images/postopop/sidebarnav-ic-next.png') center
    no-repeat;
  transition: all ease 0.25s;
}

.sidebar-nav-subnivel-item.subnivel.active > a:before {
  transform: rotate(180deg);
}

.sidebar-nab-subnivel-sub {
  background: rgba(0, 0, 0, 0.35);
  color: #fff;
  transition: all ease 0.25s;
  transition-delay: 0.15s;
  opacity: 0;
  transform: translateX(20px);
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nab-subnivel-sub > li {
  display: flex;
  height: 35px;
  padding: 0 15px;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  transition: all ease 0.25s;
}

.sidebar-nab-subnivel-sub > li:hover {
  background: rgba(255, 255, 255, 0.1);
}

.sidebar-nav-subnivel-item {
  transition: all ease 0.25s;
}

.sidebar-nav-subnivel-item.hide,
.sidebar-nav-subnivel-item.hide > a {
  opacity: 0;
  height: 0px;
}

.sidebar-nab-subnivel-sub.active {
  opacity: 1;
  transform: translateX(0);
}

/* ==========================================================================
 DropDown
 ========================================================================== */

.dropdown {
  position: relative;
}

.dropdown:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url('../../assets/images/postopop/dropdown-ic-down.png') center
    no-repeat;
  margin-left: 15px;
}

.dropdown-menu {
  position: absolute;
  height: auto;
  top: 100%;
  left: -1px;
  right: 0px;
  background: #f7f8f9;
  border: solid 1px #ededed;
  transition: all ease 0.25s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-15px);
  z-index: 9;
  width: fit-content;
}

.dropdown:hover > .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.dropdown-item {
  padding: 15px 10px;
  box-sizing: border-box;
  border-bottom: solid 1px #ededed;
  font-size: 11px;
  transition: all ease 0.25s;
}

.dropdown-item:last-child {
  border-bottom: none;
}

// .dropdown-item:hover {
//   background: #FFF;
//   padding-left: 15px;
// }

/* ==========================================================================
 Sidebar Favoritos
 ========================================================================== */

.sidebar-favoritos {
  position: absolute;
  top: 65px;
  left: 220px;
  width: 250px;
  height: 320px;
  background: #f7f8f9;
  border: solid 1px #ededed;
  box-sizing: border-box;
  padding: 15px;
  transition: all ease 0.25s;
  transform: translateX(30px);
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}

.sidebar-favoritos.active {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}

.sidebar-favoritos-title {
  font-size: 11px;
  font-weight: 500;
  display: block;
}

.sidebar-favoritos-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  margin-left: -5px;
  margin-right: -5px;
  list-style: none;
}

.sidebar-favoritos-item {
  flex: 0.2;
  box-sizing: border-box;
  padding: 5px;
  min-width: 50%;
  height: 90px;
  display: flex;
  border-radius: 2px;
}

.sidebar-favoritos-item-content {
  font-size: 10px;
  background: #fff;
  display: flex;
  flex: 1;
  border: solid 1px #ededed;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.sidebar-favoritos-item-content > span {
  margin-top: 12px;
}

/* ==========================================================================
 Tabela
 ========================================================================== */
.ReactTable .-pagination {
  justify-content: flex-end !important;
  margin-top: 25px;
  align-items: flex-end !important;
}
.ReactTable .-pagination .-btn {
  background: #f7f8f9 !important;
  border: solid 1px #ededed !important;
  color: #928ca3 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 1 !important;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  max-width: 80px;
  height: 40px;
}
.ReactTable .-pagination .-pageInfo,
.ReactTable .-pagination .-pageSizeOptions {
  color: #928ca3;
  font-size: 13px;
  margin: 0 !important;
}
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  height: 40px;
  margin: 0 5px;
  font-size: 13px !important;
}
.ReactTable .-pagination .-center {
  flex: none !important;
  margin: 0 25px;
}
.ReactTable .-pagination .-pageJump input {
  width: 50px !important;
}

.table-ctn .ReactTable {
  border: none;
}

.rttbl .ReactTable {
  border-radius: 7px;
}

.rttbl .ReactTable .rt-thead .rt-th,
.table thead tr th {
  padding: 11px 10px;
  color: #928ca3;
  font-size: 13px;
  font-weight: normal;
  height: 40px;
  border-right: none !important;
  padding-left: 15px !important;
  box-sizing: border-box !important;
  outline: none;
}

.rttbl .ReactTable .rt-tbody .rt-td {
  display: grid;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
}

.rttbl .rt-tr .rt-td:first-child,
.table tbody tr td:first-child {
  color: #5b2e90;
  font-weight: bold;
  padding-left: 20px;
}

.rttbl .rt-resizable-header-content:after,
.table thead tr th div:after {
  display: none;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
}

.rt-tr-group {
  min-height: 60px;
}

.ReactTable .rt-td {
  white-space: normal !important;
  overflow: visible !important;
}

.ReactTable .rt-tbody .rt-td {
  border-right: none !important;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
}

.ReactTable .rt-tbody .rt-td .row {
  margin: 0;
}

.table-container {
  position: relative;
  padding-top: 40px;
}

.table-content,
.rt-tbody {
  /* overflow: auto; */
  /* max-height: 500px; */
  border: solid 1px #ededed;
  border-radius: 3px;
  overflow-x: hidden !important;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table .button.ic,
.ReactTable .button.ic {
  width: 35px;
  height: 35px;
  padding: 0;
  margin-left: 0;
  margin-right: 10px;
}

.table-body {
  border: solid 1px #ededed;
  border-radius: 3px;
}

.ReactTable .-pagination {
  box-shadow: none !important;
  border: none !important;
}

.rt-th {
  position: relative;
}

.ReactTable .rt-thead .rt-th,
.table thead tr th {
  text-align: left;
  padding: 0 10px;
  color: #928ca3;
  font-size: 13px;
  font-weight: normal;
  /* height: 0px; */
  height: 40px;
  border-right: none !important;
  padding-left: 15px !important;
  box-sizing: border-box !important;
  outline: none;
}

.ReactTable .rt-thead .rt-th:before {
  content: '';
  height: 15px;
  width: 1px;
  left: 0px;
  bottom: 0;
  background: #ededed;
  position: absolute;
}

.ReactTable .rt-thead .rt-th:first-child:before {
  display: none;
}

.table thead tr th div {
  position: absolute;
  top: 0;
  height: 40px;
  display: flex;
  align-items: center;
}

.table thead tr th div:before {
  content: '';
  height: 15px;
  width: 1px;
  left: -10px;
  bottom: 0;
  background: #ededed;
  position: absolute;
}

.rt-resizable-header-content:after,
.table thead tr th div:after {
  content: '';
  /* width: 6px;
  height: 5px; */
  margin-left: 5px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #958fa5 transparent transparent transparent;
  /* background: url('../../assets/images/postopop/table-ic-drop.png') no-repeat; */
}

.table-select-all-column0
  .rt-resizable-header:nth-child(1)
  .rt-resizable-header-content:after {
  display: none;
}

.table-buttons-last-column
  .rt-resizable-header:last-child
  .rt-resizable-header-content:after {
  display: none;
}

.rt-th.-sort-asc .rt-resizable-header-content:after {
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #958fa5 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}

.table thead tr th:first-child {
  padding-left: 20px;
}

.table thead tr th:last-child {
  padding-right: 20px;
}

.table tbody tr {
  transition: all ease 0.25s;
}

.table tbody tr:hover {
  background: #f7f8f9;
}

.table tbody tr td {
  height: 60px;
  padding: 0 10px;
  margin: 0;
  border-bottom: solid 1px #ededed;
  font-size: 14px;
  color: #000;
}

.rt-tr .rt-td:first-child,
.table tbody tr td:first-child {
  // color: #5b2e90;
  font-weight: bold;
  padding-left: 20px;
}

.table tbody tr td:last-child {
  padding-right: 20px;
}

.table tbody tr td.secondary {
  color: #928ca3;
}

.table tbody tr td.actions,
.table thead tr th.actions {
  width: 70px;
}

.table-ctn .react-grid-Header,
.table-ctn .react-grid-HeaderRow,
.table-ctn .react-grid-HeaderRow > div,
.table-ctn .react-grid-HeaderCell {
  height: 40px;
  min-height: 40px;
  box-sizing: border-box;
}

.table-ctn .react-grid-Viewport {
  top: 40px;
}

.table-ctn .react-grid-Main {
  outline: none;
}

.table-ctn .react-grid-Grid {
  border: none;
}

.table-ctn .react-grid-Header {
  background: #fff;
  overflow: hidden;
}

.table-ctn .react-grid-HeaderRow {
  overflow: hidden;
}

.table-ctn
  .react-grid-HeaderRow
  + .react-grid-HeaderRow
  .react-grid-HeaderCell {
  background: #fff;
  border-bottom: none;
}

.table-ctn
  .react-grid-HeaderRow
  + .react-grid-HeaderRow
  .react-grid-HeaderCell:after {
  height: 100%;
}

.table-ctn .react-grid-HeaderCell input {
  width: 100%;
  border: solid 1px #ededed;
  border-radius: 3px;
  height: 35px;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 12px;
  font-size: 12px;
  width: 100%;
  // color: #5b2e90;
  font-weight: 500;
  -webkit-transition: all ease 0.35s;
  -o-transition: all ease 0.35s;
  transition: all ease 0.35s;
  font-weight: normal;
  margin-top: -6px;
}

.table-ctn .react-grid-HeaderCell {
  color: #928ca3;
  font-size: 13px;
  font-weight: normal;
  font-weight: 500;
  background: #fff;
  border-right: none;
}

.table-ctn .react-grid-HeaderCell:first-child,
.table-ctn .react-grid-Cell:first-child {
  padding-left: 20px;
}

.table-ctn .react-grid-HeaderCell:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 15px;
  left: 0;
  bottom: 0;
  background: #ededed;
}

.table-ctn .react-grid-HeaderCell:first-child:after {
  left: 10px;
}

.table-ctn .react-grid-Viewport {
  border: solid 1px #ededed;
  box-sizing: border-box;
  right: 0;
  padding: 10px;
  border-radius: 3px;
}

.table-ctn .react-grid-Cell {
  min-height: 60px;
  padding: 0 10px;
  margin: 0;
  border: none;
  border-bottom: solid 1px #ededed;
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
}

.table-ctn .react-grid-Row {
  min-height: 60px;
}

.table-ctn .react-grid-Canvas {
  overflow: auto !important;
}

/*
.table-ctn .react-grid-Toolbar {
  border: none;
  visibility: hidden;
  padding: 0;
}
*/

.table-ctn .react-grid-Toolbar .tools {
  float: left;
}

.table-ctn .react-grid-Toolbar .btn {
  /*
  padding: 0;
  margin: 0;
*/
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  background: #f7f8f9;
  color: #928ca3;
  border: solid 1px #ededed;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 10px;
}

/*
.table-ctn .react-grid-Toolbar .btn:after {
  content: 'Mostrar filtros';
  visibility: visible;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  background: #F7F8F9;
  color: #928CA3;
  border: solid 1px #EDEDED;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 10px;
}
*/
/* ==========================================================================
 loader
 ========================================================================== */

.loaderbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 200px;
  height: 200px;
  margin: auto;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
  border: solid 1px #ededed;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.3);
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 100px;
  margin-top: 40px;
}
.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.loadertext {
  font-size: 12px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
  stroke: #000;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

/* ==========================================================================
 Botões
 ========================================================================== */
.status-button {
  width: 34px;
  height: 34px;
  box-sizing: border-box;
  border-radius: 18px;
  // border: solid 2px #5b2e90;
  display: flex;
  justify-content: center;
  align-items: center;
  // color: #5b2e90;
}

.status-button .icon {
  font-size: 28px;
  line-height: 24px;
  margin-top: 3px;
  font-weight: bold;
}

.status-button.true {
  border-color: #bcbcbc;
  color: #bcbcbc;
}

.button-container {
  display: flex;
}

.button-wrapper {
  padding-top: 20px;
}

.button {
  height: 50px;
  width: 140px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  // background: #5b2e90;
  padding: 0 15px;
  box-sizing: border-box;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  transition: all ease 0.25s;
  // box-shadow: 0px 4px 15px -2px rgba(91, 46, 144, 0.5);
  margin: 0 5px;
  border: none;
  line-height: normal;
}

//.button.secondary {
  // background: #ff9200;
  // box-shadow: 0px 4px 15px -2px rgba(255, 146, 0, 0.5);
//}

.button.light {
  background: #ebebeb;
  box-shadow: 0px 4px 15px -2px rgba(235, 235, 235, 0.5);
  color: #000000;
}

.button.dashboard {
  width: 200px;
}

.button:hover {
  transform: translateY(-1px);
  opacity: 0.7;
}

.button:active {
  opacity: 1;
  transform: translate(1px, 2px);
  // box-shadow: 0px 0px 5px -2px rgba(91, 46, 144, 0.5);
}

.button.secondary:active {
  transform: translate(1px, 2px);
  // box-shadow: 0px 0px 5px -2px rgba(255, 146, 0, 0.5);
}

.button.right {
  margin-left: auto;
}

.button.ic {
  width: 50px;
}

.button.ic > .icon {
  margin: auto;
}

.button > .icon {
  margin: auto;
  margin-right: 0;
  margin-left: auto;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 2px;
}

.button > .img {
  margin: auto;
  margin-right: 0;
  margin-left: auto;
  font-size: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 2px;
}

/* ==========================================================================
 Modal
 ========================================================================== */

// .modal-bg {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.45);
//   top: 0;
//   left: 0;
//   visibility: hidden;
//   opacity: 0;
//   transition: all ease 0.35s;
//   z-index: 1029;
// }

// .modal {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1030;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   opacity: 0;
//   visibility: hidden;
//   transition: all ease 0.25s;
//   transform: translateY(100px);
// }

// .modal-header {
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-align: start;
//   align-items: flex-start;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   padding: 1rem;
//   border-bottom: 1px solid #e9ecef;
//   border-top-left-radius: 0.3rem;
//   border-top-right-radius: 0.3rem;
// }

// .modal-bg.active,
// .modal.active {
//   visibility: visible;
//   opacity: 1;
//   transform: translateY(0px);
// }

// .modal-container {
//   position: relative;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   min-width: 300px;
//   max-width: 70%;
//   pointer-events: auto;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid rgba(0, 0, 0, 0.2);
//   border-radius: 0.3rem;
//   outline: 0;
// }

// .modal-filter .modal-container {
//   width: 820px;
// }

// .modal-filter-historico .modal-container {
//   width: 65%;
// }

// .modal-close {
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   cursor: pointer;
//   transition: all ease 0.15s;
// }

// .modal-close:hover {
//   opacity: 0.6;
//   transform: scale(1.2);
// }

// .modal-close:active {
//   opacity: 1;
//   transform: scale(0.8);
// }

// .showmodal {
//   cursor: pointer;
// }

// .modal-header .ic {
//   margin-bottom: 20px;
// }

// .modal .title {
//   font-size: 18px;
//   margin-top: 5px;
//   margin-bottom: 5px;
// }

// .modal .subtitle {
//   font-size: 13px;
//   // color: #5a2d90;
//   font-weight: bold;
//   padding: 0;
//   margin: 0;
//   white-space: pre-line;
// }

// .modal-body {
//   margin: 0;
//   box-sizing: border-box;
//   overflow-y: auto;
//   overflow-x: hidden;
//   padding: 20px;
//   max-height: 70vh;
// }

// .modal-body-dashboard {
//   margin: 40px 0;
//   box-sizing: border-box;
//   overflow-y: auto;
//   overflow-x: hidden;
//   padding: 0 15px;
//   height: 400px;
// }

// .modal-body-tree {
//   margin: 40px 0;
//   width: 300px;
//   height: 500px;
//   box-sizing: border-box;
//   overflow-y: auto;
//   overflow-x: hidden;
//   padding: 0 15px;
// }

// .modal-footer {
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-pack: end;
//   justify-content: flex-end;
//   padding: 1rem;
//   border-top: 1px solid #e9ecef;
// }

// .modal-footer .button {
//   height: 40px;
//   width: 120px;
// }

// .modal-footer {
//   .btn:first-of-type {
//     margin-right: 10px;
//   }
// }

// .modal-alert .icon {
//   margin-right: 35px;
// }

// .modal-alert .body {
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
// }

// .modal-alert {
//   margin: 0 !important;
// }

.UOSlS {
  width: 45px;
  height: 45px;
}

/* ==========================================================================
 Abas
 ========================================================================== */

.tab {
  display: flex;
  margin-bottom: 30px;
}

.tab-container {
  display: flex;
  border: solid 1px #ededed;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.03);
}

.tab-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  background: #f7f8f9;
  color: #928ca3;
  border-right: solid 1px #ededed;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  transition: all ease 0.25s;
}

.tab-item:hover {
  background: #f1f2f5;
  transform: translateY(-3px);
}

.tab-item.active {
  // background: rgb(91, 46, 144);
  color: #fff;
  // box-shadow: 0 2px 15px rgba(91, 46, 144, 0.5);
}

.tab-item:last-child {
  border-right: none;
  border-radius: 0 3px 3px 0;
}

.tab-item:first-child {
  border-radius: 3px 0 0 3px;
}

.content-accordeon {
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.content-accordeon .heading {
  transition: all ease 0.15s;
}

.content-accordeon .heading:before {
  transition: all ease 0.15s;
}

.content-accordeon .heading:hover {
  opacity: 0.6;
}

.content-accordeon .heading {
  cursor: pointer;
}

.content-accordeon .heading.block:before {
  transform: rotate(180deg);
}

.content-accordeon:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -30px;
  right: -30px;
  height: 1px;
  background: #ededed;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.content-accordeon:last-child:before {
  display: none;
}

.content-accordeon:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

/* ==========================================================================
 Formulário
 ========================================================================== */

.form-input {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.form-input.has-bt .input,
.form-input.has-bt .DateInput_input {
  padding-right: 60px;
}

.table-ctn .input,
.form-input .input,
.form-input .DateInput_input {
  // border: solid 1px #5b2e90;
  border-radius: 3px;
  height: 45px;
  outline: none;
  box-sizing: border-box;
  padding: 0 12px;
  font-size: 14px;
  width: 100%;
  // color: #5b2e90;
  font-weight: 500;
  transition: all ease 0.35s;
  font-weight: normal;
}

.form-input .input.hascheckbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //height: auto;
  min-height: 45px;
}

.form-input .checkbox-label {
  display: flex;
  height: 45px;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  // color: rgb(61, 29, 150);
  margin-right: 15px;
}

.form-input .input.hascheckbox .checkboxinput {
  width: 0px;
  height: 0px;
  opacity: 0;
  position: absolute;
}

// .form-input .checkboxinput:focus ~ .checkbox {
//   border-color: #5b2e90;
//   box-shadow: 0px 2px 5px -2px rgba(91, 46, 144, 0.5);
// }

.form-input .checkboxinput:focus ~ .label {
  color: #000;
}

.form-input .checkbox {
  width: 14px;
  min-width: 14px;
  height: 14px;
  border: solid 1px rgb(178, 177, 226);
  border-radius: 3px;
  margin-right: 8px;
  position: relative;
  transition: all ease 0.15s;
}

.form-input .checkbox:after {
  content: '';
  width: 8px;
  height: 7px;
  background: url('../../assets/images/postopop/check-ic.png') no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all ease 0.15s;
  opacity: 0;
  transform: translateY(-5px);
}

.input-file-importacao {
  border: 1px solid #5b2e90;
  border-radius: 3px;
  line-height: 44px;
  height: 45px;
  font-size: 14px;
  width: 100%;
  color: #5b2e90;
  font-weight: 500;
  font-weight: 400;
  display: flex;
  position: relative;
  overflow: hidden;
}

// .form-input .input.hascheckbox .checkboxinput:checked + .checkbox {
//   border-color: #5b2e90;
//   background: #5b2e90;
//   box-shadow: 0px 2px 5px -2px rgba(91, 46, 144, 0.5);
// }

.form-input .input.hascheckbox .checkboxinput:checked + .checkbox:after {
  transform: translateY(0px);
  opacity: 1;
}

.form-input .input.hascheckbox .checkboxinput:checked ~ .label {

  font-weight: bold;
}

// .form-input .input:valid,
// .form-input .input:focus,
// .form-input .input:valid ~ .input-bt,
// .form-input .input:focus ~ .input-bt,
// .form-input .DateInput_input:focus {
//   border-color: #5b2e90;
// }

.form-input .input:valid + .input-label,
.form-input .input:focus + .input-label {
  color: #000;
}

.SingleDatePicker:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 15px;
  width: 14px;
  height: 15px;
  z-index: 9;
  background: url('../../assets/images/postopop/date-ic.png') no-repeat;
}

/* .form-input .input:valid:focus,
.form-input .DateInput_input:valid:focus,
.form-input .input:focus,
.form-input .input:valid~.input-bt,
.form-input .input:focus~.input-bt,
.form-input .DateInput_input:valid,
.form-input .DateInput_input:focus {
  border-color: #5B2E90;
} */
/*
.form-input .input:valid,
.form-input .DateInput_input:valid {
  border-color: #EDEDED;
} */

.form-input .input:valid + .input-label,
.form-input .input:focus + .input-label,
.form-input .DateInput_input:valid + .input-label,
.form-input .DateInput_input:focus + .input-label {
  color: #000;
}

.form-input .input::placeholder,
.form-input .DateInput_input::placeholder {
  color: rgb(170, 170, 170) !important;
  opacity: 1 !important;
  font-weight: normal;
  opacity: 0.5;
  font-size: 12px;
}

.form-input .input-label {
  color: #928ca3;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 6px;
}

.form-input .input-bt {
  position: absolute;
  bottom: 7px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 30px;
  border-left: solid 1px #ededed;
  transition: all ease 0.25s;
  cursor: pointer;
}

.form-input .input-bt > .icon {
  color: #5b2e90;
  font-size: 20px;
  margin-top: 2px;
}

.form-input .help {
  position: absolute;
  bottom: -16px;
  right: 10px;
  color: #928ca3;
  font-size: 10px;
  background: #fff;
  padding: 4px 8px;
  transition: all ease 0.35s;
  font-weight: 500;
  border-radius: 3px;
}

.form-input .input:focus ~ .help {
  // background: #5b2e90;
  color: #fff;
  right: 25px;
}

.DayPickerKeyboardShortcuts_show {
  display: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  // background: #5b2e90 !important;
  border: none !important;
}

.SingleDatePickerInput__withBorder,
.DateInput {
  width: 100% !important;
}

.SingleDatePickerInput__withBorder {
  border: none !important;
}

.dropdown-heading {
  border: solid 1px #ededed !important;
  border-radius: 3px !important;
  height: 45px !important;
  outline: none !important;
  box-sizing: border-box !important;
  padding: 0 12px !important;
  font-size: 14px !important;
  width: 100% !important;
  // color: #5b2e90 !important;
  font-weight: 500 !important;
  transition: all ease 0.35s !important;
  font-weight: normal !important;
}

.dropdown:after {
  display: none;
}

.dropdown-heading-value {
  padding-top: 4px;
}

.dropdown-heading-dropdown-arrow {
  padding-right: 0px !important;
  text-align: right !important;
}

.item-renderer > span {
  font-size: 13px;
  margin-bottom: 5px;
}

.select-panel input[type='text'] {
  font-size: 13px !important;
}

// .select-panel input[type='text']:focus {
//   border-color: #5b2e90 !important;
// }

.dropdown-heading-dropdown-arrow > span {
  border-color: rgb(153, 153, 153) transparent transparent !important;
}

/* ==========================================================================
 Colunas
 ========================================================================== */

.row {
  flex: 1;
  display: flex;
  margin: 0 -10px;
  margin-bottom: 20px;
}

.col-1 {
  flex: 1;
}

.col-02 {
  flex: 0 0 2%;
  max-width: 2%;
}

.col-05 {
  flex: 0 0 5%;
  max-width: 5%;
}

.col-10 {
  flex: 0 0 10%;
  max-width: 10%;
}

.col-15 {
  flex: 0 0 15%;
  max-width: 15%;
}

.col-2 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-24 {
  flex: 0 0 24%;
  max-width: 24%;
}

.col-3 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-6 {
  flex: 0 0 16.6666%;
  max-width: 16.6666%;
}

.col-4 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-39 {
  flex: 0 0 39%;
  max-width: 39%;
}

.col-40 {
  flex: 0 0 40%;
  max-width: 40%;
}

.col-45 {
  flex: 0 0 45%;
  max-width: 45%;
}

.col-49 {
  flex: 0 0 49%;
  max-width: 49%;
}

.col-5 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-8 {
  flex: 0 0 80%;
  max-width: 80%;
}

.col-9 {
  flex: 0 0 90%;
  max-width: 90%;
}

// div[class^="col-"] .button {
//   align-self: flex-end;
// }

// div[class*="col-"] > .row {
//   // margin: 0 -10px;
//   flex: 1;
// }

// div[class*="col-"] {
//   box-sizing: border-box;
//   // padding: 0 10px;
//   display: flex;
// }

/* ==========================================================================
 Section
 ========================================================================== */

.section-header {
  width: 100%;
  height: 89px;
  display: flex;
  box-sizing: border-box;
  // padding: 0 20px;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 11;
}

.section-header-paper {
  width: 100%;
  height: 50px;
  display: flex;
  box-sizing: border-box;
  padding: 0 10px;
  align-items: center;
  border-bottom: solid 1px #eeefef;
  position: sticky;
  top: 0px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
}

.section-header-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.section-header-right {
  display: flex;
  flex-direction: row;
}

.payhub-right {
  display: flex;
  flex-direction: row-reverse;
}

.section-header-right > h5 {
  display: flex;
  flex-direction: row;
  color: #524f56;
}

.section-header-content > h1 {
  font-size: 22px;
  color: #000;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 5px;
}

.section-header-content > h2 {
  // color: #5b2e90;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 5px;
}

.payhub > h5 {
  font-size: 14px;
  margin: 0.1rem;
  margin-bottom: 0.1;
  padding: 0;
  font-weight: normal;
  margin-bottom: 5px;
}

.payhub > h1 {
  font-size: 25px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 5px;
}

.section-header-content > p {
  color: #928ca3;
  font-size: 11px;
  margin: 0;
  padding: 0;
}

.section-header .button {
  height: 40px;
  width: 120px;
}

.section-header .button.ic {
  width: 40px;
  padding: 5px 0;
}

.section-header-back {
  width: 45px;
  height: 100%;
  background: url('../../assets/images/postopop/header-ic-voltar.png') no-repeat;
  background-position-x: 0;
  background-position-y: center;
  transition: all ease 0.15s;
  cursor: pointer;
}

.section-header-home {
  width: 45px;
  height: 100%;
  background: url('../../assets/images/postopop/header-ic-home.png') no-repeat;
  background-position-x: 0;
  background-position-y: center;
  transition: all ease 0.15s;
  cursor: pointer;
}

.section-header-back:hover {
  background-position-x: 5px;
}

.section-header-search {
  width: 195px;
}

.section-content {
  box-sizing: border-box;
  padding: 15px 0px;
}

.section-footer {
  box-sizing: border-box;
  padding: 0 25px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.secion-body {
  background: #fff;
  border-radius: 3px;
  border: solid 1px #eeefef;
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 12px;
}

.section-content-payhub {
  box-sizing: border-box;
  padding: 10px 0;

  &:first-of-type {
    padding-top: 0;
  }
}

.secion-body-payhub {
  background: #fff;
  border-radius: 3px;
  border: solid 1px #eeefef;
  box-sizing: border-box;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: 15px 15px 0px 15px;

  .row:nth-of-type(2) {
    margin-bottom: 0;
  }
}

.heading {
  font-size: 18px;
  line-height: 18px;
  color: #000;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.heading:before {
  content: '';
  display: block;
  background: url('../../assets/images/postopop/heading-ic-drop.png') center
    no-repeat;
  width: 11px;
  height: 7px;
  margin-right: 10px;
}

.content {
  display: block;
  box-sizing: border-box;
}

.content-hidden {
  display: none;
}

.content-divider {
  height: 1px;
  background: #ededed;
  margin: 30px -30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.content-divider1 {
  height: 5px;
  background: #ededed;
  margin: 30px -30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.flex-right {
  display: flex;
  flex-direction: row;
  margin-right: 0px;
  margin-left: auto;
}

.teste {
  overflow: hidden;
}

/* ==========================================================================
 Animacoes Página
 ========================================================================== */

// .section-content {
//   transition: all ease .45s;
//   animation:  section .45s .15s backwards;
// }

// .section-header {
//   transition: all ease .45s;
//   animation:  section .45s backwards;
// }

// #showlistaempresa .section-content {
//   transition: all ease .45s;
//   animation:  section .45s .60s backwards;
// }

// #showlistaempresa .section-header {
//   transition: all ease .45s;
//   animation:  section .45s .45s backwards;
// }

// @keyframes section {
//   0% {
//       transform: translateY(100px);
//       opacity: 0;
//   }
//   100% {
//       transform: translateY(0);
//       opacity: 1;
//   }
// }

// .sidebar-nav {
//   transition: all ease .45s;
//   animation:  navbar .45s backwards;
// }

// @keyframes navbar {
//   0% {
//       transform: translateX(-200px);
//       opacity: 0;
//   }
//   100% {
//       transform: translateX(0);
//       opacity: 1;
//   }
// }

// .body-container {
//   transition: all ease .45s;
//   animation:  body .45s .25s backwards;
// }

// @keyframes body {
//   0% {
//       transform: translateX(200px);
//       opacity: 0;
//   }
//   100% {
//       transform: translateX(0);
//       opacity: 1;
//   }
// }

// body {
//   /* animation:  htmlbody .1s .95s backwards; */
//   overflow: hidden;
// }

// @keyframes htmlbody {
//   0% {
//       overflow: auto;
//   }
//   100% {
//       overflow: auto;
//   }
// }

.dashboard-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 46px !important;
}

.dashboard-wrapper_contas {
  display: flex;
  justify-content: space-between;
}

.dashboard {
  flex: 1;
  padding: 0px !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid rgb(235, 236, 240) !important;
  height: 350px;
  overflow-y: auto;
  background-color: white;

  &:nth-of-type(1) {
    margin: 0 30px 15px 0;
  }

  h3 {
    margin-top: 10px;
  }
}

.dashboard header.section-header-paper {
  padding: 10px 15px !important;
  width: auto;
  height: 50px;
}
.dashboard .section-header-content > h2 {
  margin-bottom: 0;
  margin-top: 0;
}

div.dashboard table th {
  color: rgb(94, 108, 132) !important;
  padding: 20px 4px !important;
  background: #f7f8f9 !important;
}
div.dashboard table td {
  border-bottom: 1px solid rgb(235, 236, 240);
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
div.dashboard table th:first-child,
div.dashboard table td:first-child {
  padding-left: 20px !important;
}
div.dashboard table th:last-child,
div.dashboard table td:last-child {
  padding-right: 20px !important;
}
div.dashboard table tr:hover td {
  background: #f5f6f7 !important;
}

.payhub {
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: -webkit-fill-available;
  position: relative;
  min-height: 87px;
}
.payhub > h5 {
  font-size: 12px;
  position: absolute;
  top: 12px;
}
.payhub > h4 {
  position: absolute;
  top: 10px;
  left: 10px;
}
.payhub > h5.sub {
  position: relative;
  top: auto;
  margin-top: -10px;
  color: rgb(94, 108, 132);
  margin-bottom: 5px;
}
.payhub > h1 {
  color: #5b2e90;
  margin-bottom: 12px;
  font-size: 20px;
}

.payhub-recebimentos {
  min-height: 95px;
}

.payhub-antecipacao {
  .form-input {
    max-width: 35% !important;
  }

  button {
    width: 165px;
    margin-left: 0;
  }
}

.payhub-antecipacao__operacoes {
  padding: 0 !important;
  display: flex;

  button {
    width: 165px;
    margin-right: 0;
  }
}

.button.dashboard {
  text-align: center;
  padding: 0 15px !important;
}

.payhub-selectDate {
  display: flex;

  button {
    width: 165px;
    margin-right: 0;
    height: 50px;
    padding-top: 20px;
  }
}

.payhub-controls {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  align-items: flex-end;

  .form-input {
    max-width: 65%;

    button {
      margin-right: 0;
    }
  }
  button {
    margin-right: 0;
  }
}

.button.cargaInicial {
  padding: 0 15px;
  height: 100%;
  width: 100%;
  font-size: 25px !important;
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
  .sidebar-nav-head {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .sidebar-nav-head > a {
    height: 58px;
    line-height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  .sidebar-nav-head > a > img {
    height: auto;
    max-width: 176px;
    min-width: 176px;
    transform: translateX(67px);
    transition: all ease 0.35s;
  }

  .sidebar-nav.active .sidebar-nav-head > a > img {
    transform: translateX(0px);
  }

  .body-container {
    margin-left: 62px;
  }
  .sidebar-favoritos {
    left: 62px;
  }
  .sidebar-nav {
    width: 62px;
    z-index: 20;
    transition: all ease 0.35s;
  }
  .sidebar-nav.active {
    width: 220px;
  }
  .section-content,
  .secion-body {
    padding: 20px;
  }
  .content-divider {
    margin: 30px -20px;
  }
  .section-footer {
    padding: 0 16px;
  }
  .sidebar-nav-head-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 40px 0;
    flex-direction: column;
  }
  .sidebar-nav-head-content > .bt {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-top: solid 1px rgba(255, 255, 255, 0.15);
    cursor: pointer;
    background: rgba(0, 0, 0, 0.15);
  }
  .sidebar-nav-head-content > .bt > img {
    transition: all ease 0.25s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .sidebar-nav-head-content > .bt > img.close {
    opacity: 0;
  }
  .sidebar-nav.active .sidebar-nav-head-content > .bt > img.close {
    opacity: 1;
  }
  .sidebar-nav.active .sidebar-nav-head-content > .bt > img.open {
    opacity: 0;
  }
  .sidebar-nav-head > .logo {
    width: 120px;
    margin-bottom: 50px;
    opacity: 0;
  }
  .sidebar-nav .sidebar-nav-head-content > .logo,
  .sidebar-nav .sidebar-nav-head > .logo {
    transition: all ease 0.25s;
  }
  .sidebar-nav.active .sidebar-nav-head > .logo {
    opacity: 1;
  }
  .sidebar-nav.active .sidebar-nav-head-content > .logo {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .body-container {
    margin-left: 0px;
  }
  .sidebar-favoritos {
    left: 0px;
  }
  .body-container {
    padding-top: 50px;
  }
  .header {
    height: 50px;
  }
  .header-bt {
    padding: 0 12px;
  }
  .header-content {
    display: none;
  }
  #showmenu {
    display: flex;
    padding: 0 20px;
  }
  #showfavoritos {
    display: none;
  }
  /*
  #showmenu:hover+.header-content {
      display: block;
      position: absolute;
      width: 100%;
      background: #F7F8F9;
      z-index: 10;
      top: 51px;
      flex-direction: column;
  }
  */
  .header-content .header-bt {
    height: 40px;
    border-bottom: solid 1px #ededed;
  }
  .sidebar-nav-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    // background: #5b2e90;
    z-index: 10;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .sidebar-nav-mobile > h2 {
    font-weight: 100;
    margin: 0;
    padding: 0;
    font-size: 15px;
    flex: 1;
  }
  .sidebar-nav-mobile > .bt {
    border-right: solid 1px rgba(255, 255, 255, 0.15);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    margin-right: 15px;
  }
  .sidebar-nav-mobile > .logo {
    margin-right: 15px;
  }
  .sidebar-nav {
    display: none;
    width: 100%;
    z-index: 20;
    top: 50px;
    // border-top: solid 1px #5b2e90;
  }

  .sidebar-nav-head {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .sidebar-nav-head > a {
    height: 50px;
    line-height: 55px;
  }
  .sidebar-nav-head > a > img {
    height: auto;
  }

  .sidebar-nav-subnivel {
    top: 0px;
  }
  .section-header {
    min-height: 65px;
    height: auto;
    top: 50px;
    flex-wrap: wrap;
    padding: 15px;
  }
  .header-content.active {
    display: block;
    position: absolute;
    width: 100%;
    background: #f7f8f9;
    z-index: 12;
    top: 101px;
    flex-direction: column;
  }
  .section-header-content > h1 {
    font-size: 18px;
    line-height: 30px;
  }
  .section-content {
    padding: 10px;
  }
  .secion-body {
    padding: 15px;
  }
  .heading {
    margin-top: 5px;
    font-size: 16px;
  }
  .row {
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
  div[class*='col-'] {
    flex: 1;
    width: 100%;
    min-width: 100%;
    margin-bottom: 15px;
  }
  .row > div[class*='col-']:last-child {
    margin-bottom: 0;
  }
  .content-divider {
    margin: 30px -15px;
  }
  .section-footer {
    padding: 0 10px;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .section-footer .button {
    flex: 1;
    width: 100%;
    min-width: 100%;
    margin: 0 0 10px 0;
  }
  .section-header .section-header-right input {
    height: 40px;
    line-height: 40px;
  }
  .form-input .input-bt {
    bottom: 4px;
    width: 40px;
  }
  .section-header-search {
    width: 150px;
  }
  .section-header-home,
  .section-header-content > p {
    display: none;
  }
  .sidebar-favoritos.active,
  .sidebar-favoritos {
    display: none;
  }
  .tab {
    flex-wrap: wrap;
  }
  .tab-container {
    overflow: auto;
  }
  .tab-item {
    white-space: nowrap;
    padding: 0 10px;
  }
  .modal-container {
    width: auto;
    margin: 0 30px;
  }
  .table-container {
    padding-top: 0px;
  }
  .table-content {
    overflow: hidden;
    max-height: initial;
    border: none;
  }
  .table .button.ic {
    width: 35px;
    height: 35px;
    padding: 0;
    margin-left: 0;
  }
  .table thead {
    display: none;
  }
  .table tbody tr td {
    display: block;
    height: auto;
    padding: 10px;
    margin: 0;
    border-bottom: solid 1px #ededed;
    border-left: solid 1px #ededed;
    border-right: solid 1px #ededed;
    font-size: 14px;
    color: #000;
  }
  .table tbody tr td:first-child {
    // color: #5b2e90;
    font-weight: bold;
    padding-left: 10px;
    background: #f7f8f9;
    border-top: solid 1px #ededed;
  }
  .table tbody tr td:last-child {
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .table tbody tr td:before {
    content: attr(data-title);
    display: block;
    font-size: 10px;
    color: #928ca3;
    margin-bottom: 3px;
    font-weight: bold;
  }

  .ReactTable .-pagination .-center {
    width: 100%;
    margin: 0;
    margin-bottom: 25px !important;
  }

  .ReactTable .-pagination {
    position: relative;
  }

  .ReactTable .-pagination .-previous {
    position: absolute;
    left: 0;
    bottom: 5px;
  }

  .ReactTable .-pagination .-previous,
  .ReactTable .-pagination .-next {
    min-width: 120px;
    max-width: 120px;
  }

  .ReactTable .-pagination .-pageSizeOptions {
    flex: 1;
    padding-left: 5px;
    box-sizing: border-box;
  }

  .ReactTable .-pagination select {
    width: 100%;
  }

  .rt-tr-group {
    height: auto;
    min-height: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .table .button.ic,
  .ReactTable .button.ic {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.form-input .checkboxinput:focus ~ .checkbox {
  border-color: #fff !important;
  // box-shadow: 0px 4px 15px -2px rgba(255, 146, 0, 0.7) !important;
  // outline: solid 2px rgba(91, 46, 144, 0.7);
}

.button:focus {
  // outline: solid 2px rgba(91, 46, 144, 0.7);
  border: solid 1px rgba(255, 255, 255, 0.7);
}

/* Context menu */
.rc-tree-contextmenu {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
  background-color: #fff;
}
.rc-tree-contextmenu-hidden {
  display: none;
}
.rc-tree-contextmenu-inner {
  padding: 0 !important;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  min-width: 150px;
}

.rc-tree-title {
  color: #000;
  font-size: 14px;
  padding-left: 3px;
}
.rc-tree li {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.rc-tree-node-selected {
  background-color: rgba(104, 41, 148, 0.1) !important;
  // border: 1px #682994 solid !important;
  opacity: 1 !important;
}

.rc-tree-contextmenu-inner h4 {
  margin: 0;
  display: block;
  padding: 10px 12px;
  border-bottom: solid 1px #ededed;
  font-size: 13px;
  transition: all ease 0.35s;
  cursor: pointer;
}

.rc-tree-contextmenu-inner h4:hover {
  // background: #5b2e90;
  color: #fff;
}

.rc-tree-contextmenu-inner h4:last-child {
  border-bottom: none;
}

.rc-tree-node-selected {
  background-color: rgba(104, 41, 148, 0.1) !important;
  // border: 1px #682994 solid !important;
  opacity: 1 !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.rc-tree-switcher.rc-tree-switcher_open {
  background: url('../../assets/images/postopop/tree-pasta-aberta.png') !important;
  width: 17px !important;
  height: 17px !important;
}

.rc-tree-switcher.rc-tree-switcher_close {
  background: url('../../assets/images/postopop/tree-pasta-fechado.png') !important;
  width: 17px !important;
  height: 17px !important;
}

.rc-tree-iconEle.rc-tree-icon__docu {
  width: 11px !important;
  height: 14px !important;
  background: url('../../assets/images/postopop/tree-pasta-file.png') !important;
  margin-bottom: 3px !important;
  margin-left: 2px;
}

.rc-tree-checkbox {
  width: 14px !important;
  height: 14px !important;
  background: url('../../assets/images/postopop/tree-pasta-check.png') !important;
}

.rc-tree-checkbox.rc-tree-checkbox-checked {
  width: 14px !important;
  height: 14px !important;
  background: url('../../assets/images/postopop/tree-pasta-checked.png') !important;
}

.rc-tree-checkbox.rc-tree-checkbox-disabled {
  opacity: 0.3;
  cursor: not-allowed !important;
}

.rc-tree-iconEle.rc-tree-icon__close,
.rc-tree-iconEle.rc-tree-icon__open {
  display: none !important;
}

.SingleDatePicker_picker {
  z-index: 10 !important;
}

/* React-select */
.input.autocomplete {
  padding: 0;
  margin: 0;
}
.input.autocomplete > div {
  border: none;
  min-height: 43px;
}
.css-d8oujb {
  background-color: #ededed !important;
}
//.css-2o5izw {
  // box-shadow: 0 0 0 1px #5b2e90 !important;
//}
//.css-z5z6cw {
  // background: #5b2e90 !important;
//}
.css-wqgs6e {
  background: #ded5e9 !important;
}

.print {
  display: none;
}

.print-nota-prazo {
  display: none;
}

.relatorio-width {
  width: 100%;
}

.centro {
  text-align: center;
}

.left {
  text-align: left;
  vertical-align: middle;
}

.left-pad {
  text-align: left;
  vertical-align: middle;
  padding-right: 10px
}

.pad {
  padding-right: 10px
}

thead {
  font-size: 14px;
}

@media print {
  @page {
    size: auto;
    margin: 0cm;
  }

  html {
    background-color: #ffffff;
    margin: 0px; /* this affects the margin on the html before sending to printer */
  }

  body {
    border: solid 1px transparent;
    position: static;
    contain: none;
    overflow: initial !important;
  }

  .sidebar-nav,
  .sidebar-nav-mobile,
  input,
  .section-header,
  .button,
  select,
  .heading,
  .form-input,
  .content,
  .table-ctn,
  // .section-content,
  .modal-bg,
  header,
  .section-footer,
  footer,
  nav {
    display: none !important;
  }

  table:not(.print) {
    display: none;
  }

  main table.print {
    display: table !important;
  }

  table.print table {
    display: table !important;
  }

  table:not(.print-nota-prazo) {
    display: none;
  }

  main table.print-nota-prazo {
    display: table !important;
  }

  table.print-nota-prazo table {
    display: table !important;
  }

  .body-content {
    margin: 0 1cm;
    display: block;
  }

  .body-container {
    margin-left: initial;
    width: 100%;
  }

  .print {
    display: block !important;
    break-after: page;
    margin-top: 1cm;
  }

  .print-nota-prazo {
    display: block !important;
    margin-top: 1cm;
    position: relative;
    overflow: initial !important;

    h3 {
      font-size: 1.4rem;

      &:not(.centro) {
        margin-top: 25px !important;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  td {
    border: solid 1px #000;
    height: 25px;
    font-size: 12px;
    padding: 5px;
    margin: 0;
    box-sizing: border-box;
  }

  table table tr td {
    border: none;
    border-bottom: solid 1px #000;
    border-right: solid 1px #000;
  }

  table table tr:last-child td {
    border-bottom: none;
  }

  table table tr td:last-child {
    border-right: none;
  }

  td.nopadding {
    padding: 0;
  }

  .td-center {
    text-align: center;
  }

  .table-venda td {
    border: none;
  }

  .table-venda .title {
    font-weight: bold;
    font-size: 18px;
  }

  .table-space {
    padding-top: 20px;
  }

  .table-conteudo {
    margin-top: 20px;
  }

  .table-assinatura {
    margin-top: 40px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .table-assinatura .assinatura {
    border-bottom: solid 2px #000;
    height: 80px;
  }

  header.header {
    display: none;
  }

  .secion-body {
    box-shadow: initial;
    border: initial;
    padding: 0;
    height: auto;
    position: relative;
    overflow: initial !important;
  }

  main {
    padding: 0 !important;
    height: auto;
    position: relative;
    overflow: initial !important;
    min-height: auto !important;
  }

  .scrollbar-container {
    position: inherit;
    overflow: visible !important;
  }
}

.chOOzT,
.daIVbG {
  font-size: 13px;
}
.daIVbG {
  align-items: center;
}
.daIVbG .button.ic {
  height: 35px;
  width: 35px;
  padding: 0;
}

.daIVbG[aria-expanded='true'] {
  background: #f5f6f7 !important;
}

.duUFtD {
  padding-left: 40px !important;
}

.cvwraw {
  padding-left: 50px !important;
}

.gRSuIS {
  padding-left: 60px !important;
}

/* ==========================================================================
 Financeiro
 ========================================================================== */

.payhub-card.secion-body {
  // background: #623299;
  box-shadow: 0 0.25rem 0.75rem rgba(98, 50, 153, 0.1);
  // border: solid 1px #623299;
  max-height: 400px;
}

.payhub-card.secion-body .button {
  width: 100%;
  margin: 0;
  margin-top: auto;
}

.payhub-card.secion-body .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

// .card {
//   background: #fff;
//   border-radius: 4px;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   max-height: 180px;
//   overflow: auto;
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// }

.payhub-card .card .item {
  height: 62px;
  min-height: 62px;
  border-bottom: solid 1px #ededed;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 500;
  flex: 1;
}

.payhub-card .card .item:last-child {
  border-bottom: none;
}

.payhub-card .card .item .ic {
  margin-right: auto;
}

.list-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 280px;
  max-height: 280px;
  margin-top: 20px;
  border: solid 1px #ededed;
  box-sizing: border-box;
  border-radius: 3px;
}

.list-flex-head,
.list-flex-content,
.lix-flext-footer {
  display: flex;
  box-sizing: border-box;
  padding: 15px;
}

.list-flex-content {
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: overlay;
}

.list-flex-content .ic {
  margin-right: 5px;
}

.list-flex .item {
  flex: 1;
  text-align: right;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.list-flex-head {
  border-bottom: solid 1px #ededed;
}

.lix-flext-footer {
  border-top: solid 1px #ededed;
  background: #f7f8f9;
}

.list-flex .item:first-child {
  text-align: left;
}

.list-flex-head,
.lix-flext-footer {
  font-weight: 600;
}

.list-flex-content .row {
  padding: 0;
  height: 30px;
  min-height: 30px;
  margin: 4px 0;
  font-size: 14px;
  flex: 0;
  width: 100%;
}

.list-flex-content .row:first-child {
  margin-top: 10px;
}

.secion-body .footer {
  border-top: solid 1px #ededed;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -30px;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 92px;
}

.secion-body .footer > .valor {
  // color: #623299;
  font-weight: 900;
  font-size: 26px;
}

.secion-body .footer > .valor:before {
  content: attr(data-title);
  color: #000;
  font-size: 13px;
  display: block;
  text-align: left;
  margin-bottom: 4px;
}

.secion-body .header {
  margin-top: 0;
  border: none;
  height: auto;
}

.secion-body .header h3 {
  margin: 0;
  padding: 0;
  line-height: 32px;
}

.toggle {
  height: 32px;
  background: #eeeaf4;
  border-radius: 20px;
  // color: #623299;
  display: flex;
  margin-left: auto;
}

.toggle .toggle-item {
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
  cursor: pointer;
}

.toggle .toggle-item.active {
  // background: #67349f;
  color: #fff;
  border-radius: 20px;
}

.kpi-valor,
.slide-card {
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
}

.slide-card::-webkit-scrollbar {
  -webkit-appearance: none;
}
.slide-card::-webkit-scrollbar:vertical {
  width: 12px;
}
.slide-card::-webkit-scrollbar:horizontal {
  height: 12px;
}
.slide-card::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}
.slide-card::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.slide-card {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  transition: all ease 0.35s;
  overflow-x: overlay;
}

.slide-card-item {
  height: 90px;
  background: #fff;
  display: flex;
  width: 100%;
  border: solid 1px #ededed;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  font-weight: 500;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  min-width: 80%;
  scroll-snap-align: center;
  -webkit-overflow-scrolling: touch;
}

.slide-card-item .ic {
  margin-bottom: auto;
}

.kpi-valor {
  color: #623299;
  font-weight: 900;
  font-size: 30px;
  justify-content: center;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .secion-body .footer {
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .list-flex-content .row {
    min-height: auto;
    height: auto;
    display: block;
    border-bottom: solid 1px #ededed;
    padding: 15px 0;
  }
  .list-flex-content .row .item {
    width: 100%;
    padding: 4px 0;
    font-size: 14px;
  }
  .list-flex-content .row .item:before {
    content: attr(data-title) ':';
    font-weight: 600;
    margin-right: 5px;
    font-size: 13px;
  }
  .list-flex-head {
    display: none;
  }
  .lix-flext-footer {
    display: block;
    background: #f7f8f9;
  }
  .lix-flext-footer .item {
    padding: 4px 0;
    font-size: 13px;
    // color: #67349f;
  }
  .list-flex-content {
    margin-top: 0;
  }
  .list-flex {
    min-height: auto;
    max-height: 405px;
  }
  .list-flex-content .row:first-child {
    margin-top: 0px;
  }
}

.section-container > .row {
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
}

.section-container > .row + .row {
  padding-top: 0px;
  margin-top: -15px;
}

.section-container > .row .section-content {
  flex: 1;
  padding: 15px;
  display: block;
}

.section-container > .row .section-content .secion-body {
  height: 100%;
}

.dashboard table td,
.dashboard table th {
  padding: 4px !important;
  font-size: 0.7525rem !important;
  background-color: white;
  border: none;
}

.dashboard table tr {
  height: 32px;
}

.dashboard .section-header-paper {
  padding: 4px !important;
}

.responsive {
  width: 100%;
  vertical-align: text-top;
  height: auto;
}

.sidebar-nav-head > a {
  text-align: center;
}
.sidebar-nav-head > a > img {
  max-width: 80%;
  margin: 0 auto;
}
.header {
  height: 50px;
}
header.section-header {
  height: 65px;
}
.header-content {
  justify-content: flex-end;
}

/* FLUXO DE CAIXA ======================================================================*/

.table-content-bd {
  max-width: 100%;
  overflow: auto;
}
.table-content-bd table {
  max-width: 100%;
  overflow: hidden;
  border-collapse: collapse;
}
.table-content-bd table thead tr th {
  color: #928ca3;
  font-size: 13px;
  font-weight: 500;
  border-bottom: solid 1px #ededed;
  height: 40px;
}
.table-content-bd table tbody tr:first-child td {
  padding-top: 15px;
}
.table-content-bd table tbody tr td {
  font-size: 13px;
  color: #000;
  padding: 8px 5px;
  font-weight: normal;
  transition: all ease 0.15s;
  min-width: 78.5px;
}
.table-content-bd table tbody tr:hover td {
  // background: rgba(105, 53, 161, 1);
  color: #fff;
}

.iframePop {
  z-index: 0;
  position: relative !important;
  height: calc(100vh - 87px) !important;
  width: 100% !important;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border: none;
  padding-bottom: 20px;
  // margin-top: 4px;
}

.iframePopReduzidoMenuTop {
  z-index: 0;
  position: relative !important;
  height: calc(100vh - 187px) !important;
  width: 100% !important;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border: none;
}

.iframePopReduzidoMenuLeft {
  z-index: 0;
  position: relative !important;
  height: calc(100vh - 137px) !important;
  width: 100% !important;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border: none;
}
