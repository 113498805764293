@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.empresaOptions {
  .navItem {
    .navLink {
      display: block;
      padding: 0.5rem 1rem;
      cursor: default;
      .storeIcon {
        cursor: default;
      }
      cursor: pointer;
    }
  }

  
}
