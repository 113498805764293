.pr0{
  padding-right: 0px !important;
}

.pl0{
  padding-left: 0px !important;
}

.row{
  margin-bottom: 0px !important;
}

.header {
  height: 60px;
  background-color: #fff;
  div: {
    height: 60px;
  }
}
