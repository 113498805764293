@import '../../../scss/linxCustom';
@import '../../../scss/mixins';

.SuporteButton {
  .LinkSupport {
    position: absolute;
    right: 20px;
    bottom: 0;
  }
}
