@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.conferenciaTotais {
  margin-top: -16px;

  .situacao {
    max-width: fit-content;
    border-radius: 9px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    margin-right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: 1em;

    .conferido {
      max-width: fit-content;
      border-radius: 9px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
      color: #ffffff;
      margin-right: 10px;
      height: 25px;
      display: flex;
      align-items: center;
      margin-left: 1em;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    }

    .parcialmenteConferido {
      background-color: $warning-color;
      max-width: max-content;
      border-radius: 9px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
      color: #ffffff;
      margin-right: 10px;
      height: 25px;
      display: flex;
      align-items: center;
      margin-left: 1em;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    }

    .naoConferido {
      background-color: rgb(109, 109, 109);
      max-width: max-content;
      border-radius: 9px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
      color: #ffffff;
      margin-right: 10px;
      height: 25px;
      display: flex;
      align-items: center;
      margin-left: 1em;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    }

    .abertoNoPdv {
      background-color: $info-color-hover;
      max-width: fit-content;
      border-radius: 9px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
      color: #ffffff;
      margin-right: 10px;
      height: 25px;
      display: flex;
      align-items: center;
      margin-left: 1em;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    }

    .vl {
      border-left: 5px solid $gray-color;
      height: 80px;
      position: absolute;
      left: 50%;
      margin-left: -3px;
      top: 0;
    }

    .accordionContent {
      min-height: fit-content;
      max-height: fit-content;
      transition: all ease-in-out 0.2s;
      margin-bottom: 40px;
    }
  }

  .info {
    // max-width: fit-content;
    // border-radius: 9px;
    // padding-left: 10px;
    // padding-right: 10px;
    // padding-bottom: 2px;
    // color: #ffffff;
    // margin-right: 10px;
    // height: 25px;
    // display: flex;
    // align-items: center;
    // margin-left: 1em;
    // cursor: pointer;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    cursor: pointer;

    span {

      i {
        font-size: 25px;
      }
    }
  }

  .formaPagamentoColumn {
    padding-top: 23px;
    font-size: 1.5rem;
  }

  .formaPagamentoTotal {
    padding-top: 1px;
    font-size: 1.5rem;
    padding-left: 15px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 70px;
    top: 5px;
    left: 90%;
    background-color: #303030;
    color: $gray-color;
    border: $gray-color;
    text-align: center;
    padding: 5px 0;
    font-size: 0.65em;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
