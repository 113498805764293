.validationError{
  position: absolute;
  font-size: small !important;
  font-style: italic;
}

select {
  font-size: small !important;
}


.curreny input{
  text-align: right;
  font-weight: bold !important;
  font-size: large !important;
}

.form-label{
  font-size: small !important;
}
