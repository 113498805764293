#blip-chat-open-iframe { 
    content: url('https://cliente.linx.com.br/assets/imgs/cta-lais-i.png') !important; 
    border-radius: 0% !important; 
    box-shadow: 0 0px 0px !important; 
    max-width: 200px !important; 
    min-width: 200px !important; 
    max-height: 150px !important;
    min-height: 150px !important; 
    background-color: transparent !important; 
    right: 15px !important;
    bottom: 10px !important;
  } 
  
  #blip-chat-container #blip-chat-iframe { 
    bottom: 140px !important; 
  } 
  
  #blip-chat-container #blip-chat-iframe.blip-chat-iframe-opened { 
    height: 500px !important; 
  }