@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.perfilOptions {
  .navItem {
    border-left: 1px solid #EEEEEE;
    .navLink {
      display: block;
      padding: 0.5rem 1rem;
    }

    .dropdownOverlay {
      z-index: 999;
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
    }

    .dropdownMenu {
      overflow-y: auto;
      max-width: 25rem;
      min-width: 25rem;
      max-height: 590px;

      @include device(smartphone) {
        max-width: 16rem;
        min-width: 16rem;
      }

      .notificationFooter {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        background-color: $white-color;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 5px;

        @include device(smartphone) {
          display: none;
        }
      }
    }
  }
}
