@import './../../../scss/linxCustom';
@import './../../../scss/mixins';

.nav-pills .nav-link.active {
  background-color: $primary-color !important;
}
.dx-button-mode-contained.dx-button-primary {
  color: #fff !important;
  border-color: transparent !important;
  background-color: #5b2e90 !important;
}
.dx-button-mode-contained.dx-button-primary.dx-state-focused,
.dx-button-mode-contained.dx-button-primary.dx-state-hover {
  color: #fff !important;
  border-color: transparent !important;
  background-color: #2c004b !important;
}

.dx-button-mode-outlined.dx-button-primary{
  color: #5b2e90 !important;
  border-color: #5b2e90 !important;
}
.dx-button-mode-outlined.dx-button-primary.dx-state-focused,
.dx-button-mode-outlined.dx-button-primary.dx-state-hover{
  color: #2c004b !important;
  border-color: #2c004b !important;
  background-color: rgba($color: #2c004b, $alpha: 0.20)
}

