@page {
  margin: 0 !important;
}

// .ui.container {
//   background-color: #fff !important;
//   width: 210mm;
//   height: 297mm;
// }

.noScroll {
  max-height: unset !important;
}

.overflowVisible {
  overflow: auto;
  overflow-anchor: auto;
}

.sheet {
  overflow: hidden !important;
  position: relative !important;
  box-sizing: border-box !important;
  page-break-after: always !important;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  p {
    font-size: 10px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
  }
  hr {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }
  h1 {
    font-family: Calibri, sans-serif !important;
    color: #585858;
    font-style: normal !important;
    font-weight: bold !important;
    text-decoration: none;
    display: inline !important;
    font-size: 11pt !important;
    margin: 0px !important;
  }
  h1 strong{
    font-family: Calibri, sans-serif !important;
    color: #000;
    font-style: normal !important;
    font-weight: bold !important;
    text-decoration: none;
    display: inline !important;
    font-size: 12pt !important;
  }
  h1 small {
    font-family: Calibri, sans-serif !important;
    color: #585858;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: none !important;
    font-size: 11pt !important;
  }
  h2 {
    display: inline !important;
  }
  h3 {
    font-family: Calibri, sans-serif !important;
    color: #000;
    font-style: normal !important;
    font-weight: bold !important;
    text-decoration: none !important;
    font-size: 9pt !important;
  }
  p {
    font-family: Calibri, sans-serif !important;
    color: #767070;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: none !important;
    font-size: 9pt !important;
  }
  label {
    font-family: Arial, Helvetica, sans-serif;
  }
  td p {
    font-family: Calibri, sans-serif !important;
    color: #767070;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: none !important;
    font-size: 8pt !important;
  }

  table {
    border: 1px solid #000;
  }
  table tr {
    border: 1px solid #000;
  }
  table td {
    border: 1px solid #000;
  }
  table th {
    border: 1px solid #000;
    font-family: Calibri, sans-serif !important;
    color: #767070;
    font-style: normal !important;
    font-weight: normal !important;
    text-decoration: none !important;
    font-size: 7pt !important;
  }
}
.border {
  border: 1px solid #000 !important;
  width: 100%;
}

.contrato-header {
  margin: 0 auto;
  background-color: #fff !important;
  width: 260mm;
  height: 62px;
  padding: 0px;
  h1 {
    float: left;
    margin-top: 18px;
    margin-left: 15px;
  }
  button {
    height: 100%;
    margin: 0px;
    padding: 0px;
    float: right;
  }
}

@media not print {
  .sheet {
    background: white !important;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3) !important;
    padding: 15mm;
    width: 260mm;
    margin: 0 auto;
  }
}
@media print {
  .sheet {
    background: white !important;
    box-shadow: unset !important;
    margin: 5mm;
    padding: 0mm;
  }
}
