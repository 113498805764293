@import '../../../../scss/linxCustom';
@import '../../../../scss/mixins';

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;

  border-radius: 10px;

  background: #003a60;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 1040px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .textoContainer {
    width: 30%;

    p {
      font-size: 13px;
    }

    span {
      font-size: 13px;
    }

    @media (max-width: 1040px) {
      width: 80%;
      margin-top: 1.5rem;
    }

    h1 {
      color: $primary-color;
    }
  }

  .separador {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

.videoResponsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.linkAppContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linkAppContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.credenciamentoPendenteContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  span {
    margin-top: 5px;
  }

  button {
    width: 150px;
  }

  strong {
    color: $primary-color;
  }
}
